@import '../../../colors';

.field {
  display: block;
  width: 100%;
  border: 1px solid $grey-gainsboro-2;
  border-radius: 8px;
  padding: 10px 45px 10px 15px;
  color: $text-color;
  font-size: 14px;
  height: 50px;
  outline: none;
  background: #fff;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  &:disabled {
    color: $grey-dim;
    opacity: 0.8;
  }

  &.error {
    border-color: $red-sunset;
  }
}

.arrow {
  position: absolute;
  top: 50%;
  right: 17px;
  margin-top: -4px;
  color: $blue-zodiac;
}

.dropdownBox {
  position: relative;
}

.dropdown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 5px;
  z-index: 20;
}

.list {
  border: 1px solid $grey-gainsboro-2;
  border-radius: 8px;
  padding: 7px 0;
  margin: 0;
  list-style: none;
  background: #fff;
  color: $text-color;
  font-size: 14px;

  li {
    padding: 7px 15px;
    cursor: pointer;

    &:hover {
      background: $white-smoke;
    }
  }
}
