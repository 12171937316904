@import '../../../../colors';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5px;
}

.item {
  padding: 0 5px 5px 0;
  width: 50%;
  height: 37px;
}

.errorItem {
  padding: 0 20px 5px 0;
}
