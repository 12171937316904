@import '../../colors';

.userEmail {
  font-size: 14px;
  color: $blue-zodiac;
  text-align: center;
  word-break: break-word;
  margin: 50px 0 5px;
}

.list {
  margin: 0 0 0 -15px;
  padding: 1px 0;
  max-height: 470px;
  overflow-y: auto;
}

.item {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    height: 1px;
    background: $grey-gainsboro-2;
  }

  &:nth-last-of-type(1) {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 15px;
      height: 1px;
      background: $grey-gainsboro-2;
    }
  }
}

.itemBtn {
  border: none;
  padding: 18px 15px;
  display: flex;
  align-items: center;
  color: $midnight;
  background: transparent;
  width: 100%;
  margin: -1px 0;
  text-align: left;
  border-radius: 10px;
  position: relative;

  &:hover {
    background: $white-smoke-3;
    z-index: 2;
  }
}

.name {
  font-size: 14px;
  flex-grow: 1;
  word-break: break-word;
}

.arrowBox {
  color: #000;
  flex-shrink: 0;
  padding-left: 15px;
}

.emptyList {
  text-align: center;
  font-size: 18px;
  padding: 40px 0 20px;
}

.logoutBox {
  font-size: 16px;
  text-align: center;

  button {
    color: $orange;
    font-weight: 600;
    padding: 0;
    border: none;
    background: transparent;

    &:hover {
      color: $grey-raven;
    }
  }
}
