.wrapper {
  padding-top: 30px;
}

.descriptionRow {
  display: flex;
  justify-content: space-around;
}

.descriptionCol {
  flex-shrink: 0;
  padding: 0 3px;
  text-align: center;
}

.percentBox {
  font-size: 20px;
  font-weight: 900;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nameBox {
  font-size: 12px;
  font-weight: 500;
  word-break: break-word;
  padding-top: 3px;
}
