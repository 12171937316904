@import '../../../colors';

.box {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0 3px 7px;
  width: 260px;
  border-radius: 8px;
}

.content {
  padding: 12px;
  max-height: 270px;
  overflow-y: auto;
}

.categoryName {
  font-size: 14px;
  color: $grey-nobel;
  text-transform: uppercase;
  padding: 0 5px 10px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 0 5px 10px;
  }
}

.btn {
  display: block;
  height: 22px;
  border: none;
  padding: 2px;
  background: transparent;
  border-radius: 3px;

  &:hover {
    background: $blue-pattens;
  }

  img {
    height: 18px;
  }
}
