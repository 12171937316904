.button {
  border: 0;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 800;
  background-color: #1d2437;
  color: #ffffff;
  padding: 6px 11px;
  border-radius: 16px;
  letter-spacing: 0.08em;
  margin-left: 20px;
}
