@import '../../../../colors';

.modal {
  padding: 30px 30px 20px;
}

.label {
  font-size: 10px;
  font-weight: 600;
  padding-bottom: 5px;
  color: $text-color;
}

.fieldsRow {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}

.fieldCol {
  width: 48%;
  position: relative;

  :global(.react-datepicker__header--time) {
    display: none;
  }
}

.input {
  display: block;
  width: 100%;
  background: #fff;
  border: 1px solid $grey-gainsboro-2;
  border-radius: 8px;
  height: 50px;
  padding: 10px 15px;
  outline: none;
}

.inputArrow {
  position: absolute;
  top: 21px;
  right: 18px;
  color: $midnight;
  pointer-events: none;
}
