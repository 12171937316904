@import '../../../colors';

.box {
  padding: 30px 30px 0;
  flex-shrink: 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 30px;
    background: -moz-linear-gradient(
      top,
      rgba($sidebar-color, 1) 0%,
      rgba($sidebar-color, 0) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba($sidebar-color, 1) 0%,
      rgba($sidebar-color, 0) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba($sidebar-color, 1) 0%,
      rgba($sidebar-color, 0) 100%
    );
    z-index: 10;
    pointer-events: none;
  }
}

.inputBox {
  position: relative;
}

.input {
  display: block;
  background-color: $blue-bunting;
  width: 100%;
  height: 40px;
  border: none;
  font-size: 12px;
  font-style: italic;
  border-radius: 8px;
  color: #fff;
  padding: 10px 40px;
  outline: none;

  &:focus,
  &.notEmpty {
    & ~ .placeholderBox {
      left: 12px;
      opacity: 0.6;

      .placeholder {
        opacity: 0;
      }
    }
  }

  &.notEmpty {
    & ~ .clearBtn {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.placeholderBox {
  display: flex;
  position: absolute;
  pointer-events: none;
  top: 12px;
  left: 35px;
  color: $grey-manatee;
  transition: all 0.15s;
}

.placeholder {
  font-size: 12px;
  font-style: italic;
  transition: all 0.15s;
  padding-left: 10px;
}

.placeholderIconBox {
  line-height: 1;
}

.clearBtn {
  display: flex;
  border: none;
  background: transparent;
  padding: 0;
  position: absolute;
  top: 11px;
  right: 11px;
  opacity: 0;
  transition: all 0.15s;
  pointer-events: none;
  color: $grey-storm;

  &:hover {
    color: $grey-manatee;
  }
}
