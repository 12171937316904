@import '../../colors';

.wrapper {
  background: #fff;
  height: 100vh;
  overflow-y: auto;
}

.row {
  display: flex;
  min-height: 100vh;
}

.leftCol,
.rightCol {
  width: 50%;
  display: flex;
  align-items: center;
}

.leftCol {
  background: url('../../assets/img/commonPageBg.png') no-repeat 0 0 $midnight;
  background-size: cover;
}

.leftContainer {
  flex-grow: 1;
  max-width: 600px;
  padding: 60px;
  margin-left: auto;
}

.rightContainer {
  flex-grow: 1;
  max-width: 500px;
  padding: 60px;
}

.logoBox {
  padding-bottom: 100px;

  button {
    background: transparent;
    border: none;
    padding: 0;
    color: $yellow-koromiko;
  }
}

.greetingTitle {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 40px;
}

.greetingText {
  color: #8d919b;
  font-size: 13px;
  line-height: 28px;
  max-width: 430px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: $blue-zodiac;
  padding-bottom: 70px;

  &.withBackButton {
    padding-bottom: 10px;
  }
}

.returnBtn {
  font-size: 10px;
  font-weight: 600;
  color: $blue-zodiac;
  background: transparent;
  border: none;
  padding: 0;
  margin-bottom: 60px;

  &:hover {
    color: $midnight;
  }
}

.returnIcon {
  color: $yellow-koromiko;
  margin-right: 8px;
}

@media all and (max-width: 767px) {
  .wrapper {
    height: auto;
  }

  .row {
    flex-direction: column;
  }

  .leftCol,
  .rightCol {
    width: 100%;
  }

  .leftContainer,
  .rightContainer {
    max-width: 500px;
    padding: 40px 30px;
    margin: 0 auto;
  }

  .logoBox {
    padding-bottom: 0;
  }

  .greetingTitle,
  .greetingText {
    display: none;
  }

  .title {
    padding-bottom: 30px;
  }
}
