@import '../../../../../colors';

.box {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.title {
  font-size: 10px;
  font-weight: 500;
  width: 70px;
  padding-right: 10px;
  color: $midnight;
}

.timeBox {
  background: $grey-whisper-3;
  border-radius: 20px;
  padding: 4px;
  display: flex;
}

.datePickerInputBox {
  width: 75px;
}

.timeBtn,
.datePickerInput {
  margin: 0 3px 0 0;
  padding: 3px 20px;
  font-size: 8px;
  font-weight: 900;
  text-transform: uppercase;
  color: $midnight;
  height: 24px;
  border: none;
  border-radius: 15px;
  background: transparent;

  &:hover {
    background: rgba($blue-zodiac, 0.2);
  }

  &.active {
    color: $white;
    background: $blue-zodiac;
  }
}

.timeBtn {
  padding-top: 4px;
}

.datePickerInput {
  &::placeholder {
    color: $midnight;
  }
}
