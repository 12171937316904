.wrapper {
  padding-top: 30px;
  position: relative;
}

.noDataBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noDataText {
  max-width: 220px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
