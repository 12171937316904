@import '../../../colors';

.userRoleBox {
  flex-shrink: 0;
  margin-left: 15px;
}

.popoverBox {
  z-index: 1001;
}

.currentRoleBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  min-height: 32px;
  border-radius: 8px;
  border: 1px solid $grey-gainsboro-2;
  background: transparent;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  text-align: left;
  padding: 9px 12px;
  color: $text-color;

  svg {
    flex-shrink: 0;
    transform: rotate(180deg);
  }
}

.rolesList {
  width: 100px;
  background: #fff;
  border: 1px solid $grey-gainsboro-2;
  border-radius: 8px;
  padding: 10px 12px;

  button {
    border: none;
    background: transparent;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    text-align: left;
    padding: 0;
    color: $text-color;

    &:hover {
      opacity: 0.8;
    }
  }

  .removeBtn {
    color: $red-sunset;
  }

  hr {
    margin: 8px 0;
  }
}

.rolePreviewRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-top: 1px solid $grey-gainsboro-2;
  border-bottom: 1px solid $grey-gainsboro-2;
  margin-top: 25px;
}

.rolePreviewUserBox {
  display: flex;
  align-items: center;
  min-width: 1px;

  img {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 10px;
  }

  span {
    font-size: 12px;
    color: $text-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.desiredRole {
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
  color: $text-color;
}
