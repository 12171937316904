@import '../../../colors';

.wrapper {
  position: relative;
  margin-left: 30px;
}

.btn {
  background: transparent;
  border: none;
  padding: 0 6px;
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  transition: all 0.15s;

  &:hover {
    color: $grey-manatee;
  }
}

.listBox {
  width: 175px;
  position: absolute;
  right: -20px;
  top: 100%;
  margin-top: 12px;
  z-index: 20;

  &.hidden {
    display: none;
  }

  button {
    min-height: 44px;
  }
}
