@import '../../../colors';

.avatarPreviewBox {
  flex-shrink: 0;
  width: 58px;
  height: 58px;
  border: 2px solid rgba($white, 0.4);
  border-radius: 6px;
  padding: 2px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
}

.dropZoneWrapper {
  flex-shrink: 0;
  outline: none;
}

.dropZoneBox {
  position: relative;

  &:not(.disabled):hover {
    cursor: pointer;

    .dropZoneHover {
      opacity: 1;
    }
  }
}

.dropZoneHover {
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  color: $white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.15s;
  border-radius: 6px;
}

.cropperWrapper {
  padding: 30px;
}

.cropperBox {
  width: 100%;
  height: 300px;
  padding-bottom: 15px;
}

.cropperButtonsBox {
  padding-top: 15px;
  display: flex;
  justify-content: center;

  & > button {
    max-width: 100px;
    min-height: 32px;
    margin: 0 5px;
  }
}

.closeCropperBtn {
  background: $grey-very-light;

  &:hover {
    background: $grey-silver;
  }

  &:disabled {
    background: $grey-very-light;
  }
}
