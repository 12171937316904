@import '../../../colors';

.modalContent {
  padding: 30px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 30px;
  color: $blue-zodiac;
}

.descriptionRow {
  font-size: 10px;
  font-weight: 600;
  color: $text-color;
  display: flex;
  padding-bottom: 10px;

  & > p {
    flex-grow: 1;

    &:nth-last-of-type(1) {
      flex-grow: 0;
      flex-shrink: 0;
      width: 130px;
    }

    & + p {
      margin-left: 10px;
    }
  }
}

.fieldsRow {
  display: flex;
}

.fieldBox {
  padding-bottom: 10px;
  flex-grow: 1;

  input {
    display: block;
    width: 100%;
    border: 1px solid $grey-gainsboro-2;
    background: #fff;
    border-radius: 8px;
    font-size: 13px;
    padding: 5px 15px;
    height: 40px;
    outline: none;

    &:disabled {
      background: $white-smoke;
      border-color: $grey-whisper;
    }

    &.errorField {
      border-color: $red-sunset;
    }
  }

  & + .fieldBox {
    margin-left: 10px;
  }
}

.selectBox {
  margin: 0 0 10px 10px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 130px;
}

.selectField {
  height: 40px;
  font-size: 13px;
}

.teamsTitle {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0;
  color: $blue-zodiac;
}

.teamsDescription {
  font-size: 12px;
  color: $text-color;
  padding-top: 10px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.btnInvite {
  min-height: 40px;
  width: auto;
  min-width: 290px;
}
