.text-editor {
  position: relative;
  //z-index: 10;

  .DraftEditor-root {
    position: relative;
    z-index: 2;
  }

  .public-DraftEditorPlaceholder-root {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    color: #999999;
  }

  .toolbar {
    position: absolute;
    z-index: 9999;
    height: 30px;
    cursor: default;
  }

  .toolbar-items {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    height: 100%;
    margin: 0;
    padding: 0;
    background: #f5f5f5;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 25px -4px rgba(0, 0, 0, 0.25);

    &:after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 50%;
      margin-left: -5px;
      border-top: 5px solid #444;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }

  .toolbar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 24px;
    max-width: 35px;
    color: #444;
    font-size: 16px;
    border-bottom: 3px solid #444;
    transition: all 0.15s linear;
    cursor: pointer;

    &.t-bold {
      font-weight: 600;
    }

    &.t-italic {
      font-style: italic;
    }

    &:hover,
    &.active {
      background: #e4e4e4;
    }
  }
}
