@import '../../../../colors';

.popoverBox {
  z-index: 500;
  overflow: visible !important;
}

.popoverOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.tabWrapper {
  width: 300px;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: #fff;
}

.tabBtnBox {
  display: flex;
}

.tabBtn {
  width: 50%;
  border: none;
  border-bottom: 3px solid transparent;
  background: $white-smoke-3;
  color: $grey-swan;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  padding: 10px;

  &:nth-of-type(1) {
    border-top-left-radius: 8px;
  }

  &:nth-last-of-type(1) {
    border-top-right-radius: 8px;
  }

  &:hover {
    background: $grey-whisper-2;
  }

  &.active {
    color: $blue-zodiac;
    border-color: $blue-zodiac;
    background: $grey-whisper-4;
  }
}

.tabContent {
  width: auto;
  box-shadow: none;
  border-radius: 0;
  background: transparent;
}
