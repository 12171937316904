@import '../../../colors';

.dropdown {
  width: 185px;
}

.arrow {
  position: absolute;
  top: 12px;
  right: 15px;
}

.btn {
  border-radius: 8px;
}
