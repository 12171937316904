@import '../../../../colors';

.item {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid $grey-gainsboro-3;
  padding: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square {
  background: $yellow-koromiko;
  width: 0;
  height: 0;
  border-radius: 4px;
  transition: all 0.15s;

  &.isChecked {
    width: 100%;
    height: 100%;
  }
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}
