@import '../../../colors';

.skipInvitationBox {
  padding-top: 40px;
  text-align: center;
  font-size: 20px;
  color: #404040;

  a {
    color: $blue-denim;
    text-decoration: none;

    &:hover {
      color: $grey-raven;
      text-decoration: none;
    }
  }
}

.fieldRow {
  display: flex;
}

.inputBox {
  flex-grow: 1;
}

.selectBox {
  margin: 0 0 10px 10px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 130px;
}
