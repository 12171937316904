@import '../../../colors';

.listWrapper {
  &.hidden {
    display: none;
  }
}

.box {
  padding: 10px 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 30px;
    right: 30px;
    height: 1px;
    background: $sidebar--active;
    background: -moz-linear-gradient(
      left,
      $sidebar--active 0%,
      $sidebar-color 100%
    );
    background: -webkit-linear-gradient(
      left,
      $sidebar--active 0%,
      $sidebar-color 100%
    );
    background: linear-gradient(
      to right,
      $sidebar--active 0%,
      $sidebar-color 100%
    );
  }

  &:nth-last-of-type(1) {
    &:after {
      display: none;
    }
  }
}
