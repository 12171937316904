@import '../../colors';

.container {
  padding: 30px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: $blue-zodiac;
  padding-bottom: 30px;
}

.description {
  color: $text-color;
  margin-bottom: 35px;
}

.row {
  padding-bottom: 10px;
}

.labelBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 10px;
  color: $text-color;
  margin-bottom: 5px;
}

.label {
  font-weight: 600;
}

.tagsBox {
  background: #fff;
  border: 1px solid $grey-gainsboro-2;
  border-radius: 8px;
  padding: 5px 5px 0;
  min-height: 52px;
}

.btnContainer {
  padding-top: 10px;
}

.btnBox {
  text-align: center;
  display: flex;
  justify-content: space-between;

  button {
    width: 48%;
  }
}

.deleteBtn {
  background: $grey-light;

  &:hover {
    background: darken($grey-light, 5%);
  }

  &:disabled {
    background: lighten($grey-light, 5%);
  }
}
