@import '../../../colors';

.muteBox {
  flex-shrink: 0;
  margin-left: 8px;
}

.btn {
  padding: 0;
  background: transparent;
  border: none;
  color: $midnight;
  display: flex;

  svg {
    flex-shrink: 0;
  }

  &:hover,
  &:disabled {
    color: $grey-storm;
  }
}
