@import "../../../../colors";

.avatarBox {
  display: flex;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $grey-gainsboro-3;
  padding: 3px;
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
