@import '../../../../colors';

.plusBtn {
  display: flex;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 8px;
  padding: 10px;
  line-height: 1;
  background: $grey-whisper-4;
  color: $midnight;
  transition: all 0.15s;

  &:hover {
    color: $grey-dim;
  }

  &:disabled {
    color: $grey-dim;
  }
}

.box {
  position: absolute;
  z-index: 15;
  bottom: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
  border: 1px solid $grey-gainsboro-2;
  padding: 15px 0;
  width: 270px;
  border-radius: 8px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: $blue-zodiac;
  padding: 0 15px 15px;
}

.inputBox {
  margin: 0 15px 12px;
  position: relative;
}

.input {
  display: block;
  width: 100%;
  border: 1px solid transparent;
  background: $grey-whisper-4;
  border-radius: 8px;
  padding: 10px 15px 10px 35px;
  font-size: 12px;
  line-height: 1;
  height: 40px;
  outline: none;

  &:focus,
  &.notEmpty {
    & ~ .placeholderBox {
      left: 12px;

      .placeholder {
        opacity: 0;
      }
    }
  }
}

.placeholderBox {
  display: flex;
  position: absolute;
  pointer-events: none;
  top: 12px;
  left: 90px;
  color: $grey-storm;
  opacity: 0.8;
  transition: all 0.15s;
}

.placeholder {
  font-size: 12px;
  font-style: italic;
  transition: all 0.15s;
  padding-left: 10px;
}

.placeholderIconBox {
  line-height: 1;
}

.contactsList {
  height: 160px;
  overflow-y: auto;
}

.itemBox {
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px 35px 8px 15px;
  margin-bottom: 1px;

  &:hover {
    background: $blue-alice;
  }

  &.selected {
    background: $blue-alice;

    .itemName {
      color: $blue-lochmara;
    }
  }
}

.checkbox {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.itemAvatar {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 50%;
}

.itemName {
  font-size: 12px;
  color: $text-color;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
}

.checkmark {
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -8px;
  color: $blue-lochmara;
  line-height: 1;
}
