.popoverBox {
  z-index: 500;
  overflow: visible !important;

  &.isCallView {
    z-index: 10001;
  }
}

.popoverOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.reactionPopover {
  z-index: 10001;
}
