@import '../../../../colors';

.wrapper {
  position: relative;
}

.content {
  color: $blue-zodiac;
  font-weight: bold;
  font-size: 18px;
  background-color: #ffffff;
  display: flex;
  height: 80px;
  align-items: center;
  padding: 0 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  justify-content: space-between;
}
.content_left {
  display: flex;
}

.upper_block {
  position: absolute;
  height: 16px;
  top: -8px;
  left: 0;
  right: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #ffffff;
  z-index: -1;
}

.closeBtn {
  background-color: transparent;
  border: 0;
}
