@import '../../../../../colors';

.actionsBox {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.action {
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 15px;
  background: $yellow-koromiko;
  border-radius: 20px;
  margin: 0 10px 10px 0;
  position: relative;

  &.edited {
    background: $white-smoke-3;
  }
}

.actionName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.4;
  color: $blue-zodiac;
}

.btn {
  background: transparent;
  border: none;
  margin-left: 8px;
  padding: 0;
  color: $blue-zodiac;
  display: flex;

  &:hover {
    color: $midnight;
  }
}

.pencilBtn {
  margin-top: -1px;
}
