@import '../../../colors';

.wrapper {
  display: flex;
  overflow: auto;
  flex-grow: 1;
  margin: 0 12px;
  position: relative;
}

.emptyWrapper {
  min-width: 200px;
  width: 100%;
  border: 2px dashed transparent;
  border-radius: 8px;
  overflow-y: auto;

  &.isOver {
    border-color: rgba($blue-bunting, 0.3);
    background: rgba($blue-bunting, 0.05);
  }
}

.leftShadow,
.rightShadow {
  position: absolute;
  z-index: 50;
  top: 0;
  bottom: 0;
  width: 30px;
  display: none;

  &.visible {
    display: block;
  }
}

.leftShadow {
  left: 0;
  background: linear-gradient(
    90deg,
    #cbced7 0%,
    rgba(203, 206, 215, 0) 75%,
    rgba(203, 206, 215, 0) 100%
  );
}

.rightShadow {
  right: 0;
  background: linear-gradient(
    270deg,
    #dadde6 0%,
    rgba(203, 206, 215, 0) 75%,
    rgba(203, 206, 215, 0) 100%
  );
}

.scrollWrapper {
  display: flex;
  overflow-x: auto;
  min-width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: darken($grey-link-water, 4%);
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: darken($grey-link-water, 12%);
    }
  }
}

.col {
  width: 190px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-right: 12px;
  transition: background 0.15s;

  &:nth-last-child(1) {
    margin-right: 0;
  }

  &.isOver {
    .colHeader,
    .colContent {
      background: darken($grey-link-water, 3%);
    }

    .emptyColContent {
      background: lighten($grey-link-water, 2%);
    }
  }

  &.isDraggingCol {
    opacity: 0;
  }
}

.colHeader {
  flex-shrink: 0;
  border-radius: 10px 10px 0 0;
  background: $grey-link-water;
}

.colContent {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px 10px 0;
  background: $grey-link-water;

  &::-webkit-scrollbar {
    width: 0;
  }

  &.noPosts {
    padding: 0;
  }
}

.emptyColContent {
  height: 100%;
  background: $blue-hawkes;
  line-height: 1;
  border: 1px dashed rgba($blue-bunting, 0.3);
  border-top: none;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emptyColContentTitle {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: $blue-bunting;
  padding-bottom: 20px;
}

.emptyColContentText {
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  color: $grey-dim;
}
