@import '../../../colors';

.modalOverlay {
  z-index: 11000;
}

.modalContent {
  padding: 30px;
}

.row {
  padding: 0 0 10px;
}

.label {
  font-weight: 600;
}

.labelBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 10px;
  color: $text-color;

  & > span {
    padding-bottom: 5px;
  }
}

.shareComment {
  background: #fff;
  border-radius: 8px;

  :global(.public-DraftEditorPlaceholder-root) {
    color: $grey-nobel;
    font-size: 14px;
    top: 16px;
    left: 21px;
  }

  :global(.public-DraftEditor-content) {
    color: $text-color;
    font-size: 14px;
    padding: 15px 20px 38px 20px;
    min-height: 95px;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid $grey-gainsboro-2;
    border-radius: 8px;

    &:focus {
      border-color: $blue-lochmara;
    }
  }
}

.postView {
  pointer-events: none;
  border: 1px solid $grey-gainsboro-2;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 10px 5px;
}

.combineUsersCol {
  padding: 5px 0;
  flex-shrink: 0;
}

.dropzoneWrap {
  position: relative;
}

.attachBtn {
  position: absolute;
  left: 15px;
  bottom: 10px;
  z-index: 2;
  background: $white;
  border: none;
  border-radius: 6px;
  padding: 5px;
  color: $grey-manatee;

  &:hover {
    color: $grey-storm;
  }
}

.dragActiveBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba($white, 0.9);
  border-radius: 8px;
  border: 2px dashed #000;
  padding: 5px 10px;
  z-index: 3;
  font-size: 18px;
  font-weight: 600;
}
