@import '../../../colors';

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 25px;
}

.dateFilterBox {
  display: flex;
  align-items: center;
}

.dateFilterTitle {
  padding: 0 15px 0 10px;
}

.dateFilterBtn {
  min-height: 40px;
  width: auto;
  margin-left: 10px;
}

.clearFilterBtn {
  min-height: 40px;
  width: auto;
  margin-right: 10px;
}

.searchBox {
  width: 200px;

  input {
    background: $white;
  }
}

.recalculateBox {
  display: flex;
  justify-content: flex-end;
}

.colName {
  flex-grow: 1;
  min-width: 120px;
  word-break: break-word;
}

.colUsers {
  flex-shrink: 0;
  width: 120px;
  text-align: center;
}

.colJoinedUsers {
  flex-shrink: 0;
  width: 130px;
  text-align: center;
}

.colPosts {
  flex-shrink: 0;
  width: 120px;
  text-align: center;
}

.colLastWeekPosts {
  flex-shrink: 0;
  width: 140px;
  text-align: center;
}

.colWebhooks {
  flex-shrink: 0;
  width: 120px;
  text-align: center;
}

.colCalls {
  flex-shrink: 0;
  width: 120px;
  text-align: center;
}

.colPlan {
  flex-shrink: 0;
  width: 120px;
}

.colCreatedAt {
  flex-shrink: 0;
  width: 120px;
}

.nameBtn {
  border: none;
  background: transparent;
  padding: 0;
  font-weight: inherit;
  font-size: inherit;
  color: inherit;

  &:hover {
    color: $yellow-koromiko;
  }
}

.chartBox {
  padding-bottom: 30px;
}
