@import '../../../colors';

.wrapper {
  background: $white;
  border-radius: 10px;
  padding: 30px;
}

.title {
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 30px;
}

.loaderBox {
  height: 400px;
}

.chartBox {
  overflow: auto;
}

.buttonsTypeBox {
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  button {
    border: 1px solid $midnight;
    border-radius: 8px;
    padding: 4px 10px;
    font-size: 14px;
    font-weight: 600;
    color: $midnight;
    background: transparent;

    & + button {
      margin-left: 20px;
    }

    &.active {
      color: $grey-storm;
      border-color: $grey-storm;
      background: $white-smoke;
    }

    &:hover {
      color: $grey-storm;
      border-color: $grey-storm;
    }
  }
}

.buttonsBox {
  display: flex;
  align-items: center;
  padding-bottom: 40px;

  button {
    border: none;
    padding: 0;
    font-size: 14px;
    color: $midnight;
    background: transparent;

    & + button {
      margin-left: 20px;
    }

    &:hover {
      color: $grey-storm;
    }
  }
}

.iconDay,
.iconWeek,
.iconMonth {
  display: inline-block;
  vertical-align: top;
  margin: 2px 6px 0 0;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}

.iconDay {
  background: #4339f2;
}

.iconWeek {
  background: #34b53a;
}

.iconMonth {
  background: #ffb200;
}
