$orange: #ffc102;
$yellow-selective: #ffb800;
$yellow-kournikova: #f6cd4e;
$yellow-koromiko: #ffb34c;
$blue-pattens: #c5daeb;
$blue-solitude: #e7eff6;
$blue-alice: #f8faff;
$blue-alice-darken: #f4f9fe;
$blue-hawkes: #dadde5;
$blue-denim: #2565ba;
$blue-lochmara: #3768a0;
$blue-royal: #3165f3;
$blue-bunting: #2d3447;
$blue-zodiac: #454c5e;
$cloud-burst: #373f52;
$white: #ffffff;
$white-smoke: #f5f5f5;
$white-smoke-2: #f7f7f7;
$white-smoke-3: #f2f2f2;
$white-smoke-4: #f0f0f0;
$white-smoke-darken: #f3f3f3;
$grey-whisper: #ebeaea;
$grey-whisper-2: #ededed;
$grey-whisper-3: #e6e6e6;
$grey-whisper-4: #ebebeb;
$grey-gainsboro: #e2e2e2;
$grey-gainsboro-2: #dbdbdb;
$grey-gainsboro-3: #dedede;
$grey-light: #d6d6d6;
$grey-link-water: #cfd3de;
$grey-very-light: #cdcdcd;
$grey-ghost: #c8c7cb;
$grey-silver: #bfbfbf;
$grey-heather: #b8babb;
$grey-spindle: #babfcf;
$grey-swan: #b3b3b3;
$grey-nobel: #999999;
$grey: #808080;
$grey-dim: #666666;
$grey-matterhorn: #4d4d4d;
$wild-blue-yonder: #8694b9;
$grey-manatee: #9195a1;
$grey-storm: #6c717e;
$grey-storm-2: #7a7d87;
$grey-raven: #60697d;
$blackcurrant: #33033c;
$black-ebony: #2c2e32;
$red-mandy: #d64658;
$red-sunset: #ff4b4b;
$red-tomato: #ff6a4c;
$midnight: #1d2437;
$amulet-green: #7fb069;
$sea-green: #219653;

$sidebar-color: $midnight;
$sidebar--hover: #262d40;
$sidebar--active: $cloud-burst;
$btn-primary: $blue-lochmara;
$btn-primary--hover: #274f7e;
$btn-primary--disabled: #89a0ba;
$btn-secondary: $yellow-koromiko;
$btn-secondary--hover: #ffba5c;
$btn-secondary--disabled: #fdc374;
$header-color: $midnight;
$text-color: $midnight;
$card-bg: $white-smoke-2;

$status-to-do: #32aaff;
$status-assigned: #ff8656;
$status-in-progress: #ffbd47;
$status-completed: #7fb069;
$status-approved: #1467d4;
