@import '../../../colors';

.box {
  display: flex;
  align-items: center;
  margin: 0 30px;
  padding: 15px 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: $sidebar--active;
    background: linear-gradient(
      to right,
      $sidebar--active 0%,
      $sidebar-color 100%
    );
  }

  &.collapsedSidebar {
    margin: 0 7px 13px;
    padding-bottom: 10px;

    &:after {
      left: 5px;
      background: linear-gradient(
        to right,
        $wild-blue-yonder 0%,
        $sidebar-color 100%
      );
    }

    .mainBox {
      flex-direction: column;
    }

    .title {
      padding: 10px 0 0;
      font-size: 8px;
    }

    .arrowBtn,
    .addBox {
      display: none;
    }
  }
}

.mainBox {
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: #fff;
}

.iconBox {
  flex-shrink: 0;
  min-width: 20px;
  line-height: 1;
}

.title {
  padding: 0 10px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  border: none;
  background: transparent;

  &:not(:disabled):hover {
    color: $grey-manatee;
  }
}

.arrowBtn {
  flex-shrink: 0;
  line-height: 0;
  padding: 0;
  background: transparent;
  border: none;
  color: #fff;
  margin-left: auto;

  &:hover {
    color: $grey-manatee;
  }
}

.arrow {
  flex-shrink: 0;

  &.down {
    transform: rotate(90deg);
  }
}

.addBox {
  flex-shrink: 0;
  display: flex;
  margin-left: 20px;
}

.addBtn {
  border: none;
  background: transparent;
  padding: 0;
  color: $grey-manatee;
  transition: all 0.15s;
  line-height: 1;

  &:hover {
    color: #fff;
  }
}
