@import '../../../../colors';

.name {
  font-size: 14px;
  font-weight: 600;
  color: $text-color;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 230px;
  padding-right: 20px;
}
