@import '../../colors';

.wrapper {
  padding: 30px 30px 20px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: $blue-zodiac;
  padding-bottom: 30px;
}

.secondScreenHeader {
  position: relative;
  padding-left: 32px;
}

.backBtn {
  position: absolute;
  top: 2px;
  left: 0;
  background: transparent;
  border: none;
  padding: 0 5px;
  color: $blue-zodiac;

  &:hover {
    color: $grey-dim;
  }
}

.description {
  padding-bottom: 30px;
}

.rowMainInfo {
  display: flex;
}

.avatarCol {
  width: 70px;
  margin-right: 30px;
  flex-shrink: 0;
}

.infoCol {
  flex-grow: 1;
}

.dropZoneWrapper {
  outline: none;
  width: 70px;
  height: 70px;
}

.dropZoneHover {
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 600;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.15s;
  border-radius: 50%;
}

.dropZoneBox {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  &:hover {
    .dropZoneHover {
      opacity: 1;
    }
  }
}

.avatar {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.defaultAvatarBox {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: $grey-whisper-3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey-nobel;
}

.cropperBox {
  width: 410px;
  height: 300px;
  margin-bottom: 20px;
}

.cropperButtonsBox {
  display: flex;
  justify-content: space-between;

  & > button {
    width: 48%;
  }
}

.closeCropperBtn {
  background: $grey-very-light;

  &:hover {
    background: $grey-silver;
  }
}

.label {
  font-size: 10px;
  font-weight: 600;
  color: #1d2437;
  padding-bottom: 4px;
}

.field {
  padding-bottom: 15px;
}

.input {
  display: block;
  width: 100%;
  min-height: 50px;
  background: #fff;
  border: 1px solid $grey-gainsboro-2;
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 1.6;
  color: $midnight;
  outline: none;
  text-overflow: ellipsis;

  &:disabled {
    background: transparent;
  }
}

.typeBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}

.typeTitle {
  font-weight: 600;
  color: $midnight;
}

.checkboxItem {
  width: 68px;
  height: 40px;
  border-radius: 20px;
  background: #ffffff;
  border: 1px solid $grey-gainsboro-3;
  padding: 3px;
  position: relative;

  &:disabled {
    background: $white-smoke-3;
  }
}

.checkIcon {
  opacity: 0;
  position: absolute;
  top: 11px;
  left: 10px;
  color: #fff;
  transition: all 0.15s;
}

.checkboxCircle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $grey-light;
  position: absolute;
  top: 7px;
  left: 8px;
  transition: all 0.15s;
}

.checkboxPrivate {
  width: 100%;
  height: 100%;
  background: $yellow-koromiko;
  border-radius: 20px;

  .checkboxCircle {
    left: 35px;
    background: #fff;
  }

  .checkIcon {
    opacity: 1;
  }
}

.checkboxInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.warning {
  font-size: 11px;
  font-style: italic;
  color: $grey-matterhorn;
  border-bottom: 1px solid $grey-gainsboro-2;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.warningIcon {
  color: $red-tomato;
  margin: -2px 10px 0 0;
}

.usersList {
  margin: 20px -30px;
  height: 245px;
  overflow-y: auto;
}

.userItem {
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px 50px 8px 30px;
  margin-bottom: 1px;

  &:hover {
    background: $white-smoke-4;
  }

  &.selected {
    background: $white-smoke-4;

    .userName {
      color: $blue-lochmara;
    }
  }
}

.userCheckbox {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.userAvatar {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 50%;
}

.userName {
  font-size: 12px;
  color: $text-color;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
}

.userCheckmark {
  position: absolute;
  top: 50%;
  right: 30px;
  margin-top: -8px;
  color: $blue-lochmara;
  line-height: 1;
}
