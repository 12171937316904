@import '../../../../../colors';

.wrapper {
  position: relative;
  flex-shrink: 0;
}

.dropzoneContent {
  outline: none;
}

.formBox {
  display: flex;
  align-items: flex-end;
}

.avatarBox {
  display: flex;
  flex-shrink: 0;
  padding: 13px 12px;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}

.actionBox {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  min-height: 50px;
  padding: 8px 15px;

  & > div,
  & > button {
    flex-shrink: 0;
  }
}

.editorBox {
  position: relative;
  padding-top: 1px;
  flex-grow: 1;
  min-width: 1px;

  :global(.public-DraftEditorPlaceholder-root) {
    color: $grey;
    font-size: 13px;
    top: 16px;
    left: 0;
  }

  :global(.public-DraftEditor-content) {
    color: $text-color;
    font-size: 13px;
    padding: 16px 0;
    max-height: 200px;
    overflow-y: auto;
  }

  [class*='__hashtag__'] {
    color: #2565ba;
    font-weight: 600;
  }
}

.infoBox {
  position: relative;

  .textStylingInfo {
    position: absolute;
    top: -1px;
    right: 0;
  }
}

.errorBox {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  width: 100%;
  background: #fff;
  z-index: 2;
}

.uploadedResultsBox {
  padding-left: 50px;
  padding-top: 20px;

  &.isCallView {
    max-height: 200px;
    overflow-y: auto;
  }
}

.commentReplyBox {
  padding: 4px 8px 0 47px;
}

.recordBtn {
  margin-right: 18px;
}

.emojiPopover {
  z-index: 10001;
}
