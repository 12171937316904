@import '../../../../../colors';

.wrapper {
  position: relative;

  &.isCallView {
    .formBox {
      flex-direction: column;
      align-items: flex-start;
    }

    .closeBtn,
    .sendBtn {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.formBox {
  display: flex;
  align-items: flex-end;
  border: 1px solid $grey-gainsboro-2;
  border-radius: 8px;
  background: #fff;
}

.attachButtonsBox {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  min-height: 50px;
  padding: 8px 15px;
}

.editorBox {
  position: relative;
  flex-grow: 1;
  min-width: 1px;

  :global(.public-DraftEditorPlaceholder-root) {
    color: $grey;
    font-size: 13px;
    top: 16px;
    left: 10px;
  }

  :global(.public-DraftEditor-content) {
    color: $text-color;
    font-size: 13px;
    padding: 16px 10px;
    max-height: 200px;
    overflow-y: auto;
  }
}

.buttonsBox {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 6px;
}

.closeBtn,
.sendBtn {
  min-height: 28px;
  font-size: 9px;
  width: auto;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 6px;
}

.closeBtn {
  background: $grey-very-light;
  margin-right: 10px;

  &:hover {
    background: $grey-silver;
  }

  &:disabled {
    background: $grey-very-light;
  }
}

.additionalInfo {
  position: relative;

  & > ul {
    position: absolute;
    top: -2px;
    right: 0;
  }
}

.errorBox {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  width: 100%;
  padding: 0 10px;
  background: #fff;
  z-index: 2;
}

.uploadedResultsBox {
  padding-top: 15px;
}

.emojiPopover {
  z-index: 10001;
}
