@import '../../../../colors';

.row {
  display: flex;
}

.inputBox {
  flex-grow: 1;
  padding-right: 15px;

  input {
    height: 30px;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 4px;
    text-overflow: ellipsis;

    &:read-only {
      color: $midnight;
      opacity: 1;
    }
  }
}

.copyBtn {
  flex-shrink: 0;
  min-height: 30px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 900;
  width: 66px;
}
