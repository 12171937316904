@import '../../colors';

.wrapper {
  display: flex;
}

.colNav {
  flex-shrink: 0;
  height: 100vh;
}

.colContent {
  flex-grow: 1;
  min-width: 1px;
  height: 100vh;
  overflow-y: auto;
  padding: 70px 50px;

  h1 {
    font-size: 38px;
    font-weight: 500;
  }

  h2 {
    font-size: 22px;
    font-weight: 500;
  }
}
