@import '../../colors';

.btn {
  background: transparent;
  border: none;
  padding: 0;
  color: $blue-zodiac;
  line-height: 1;

  &:hover {
    color: $grey-storm;
  }
}
