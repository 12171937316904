@import '../../../colors';

.box {
  width: 20px;
  height: 40px;
  margin-left: 30px;
  position: relative;
}

.inputBox {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.input {
  width: 20px;
  height: 40px;
  border: 1px solid transparent;
  background: $cloud-burst;
  border-radius: 8px;
  font-size: 12px;
  font-style: italic;
  color: #fff;
  outline: none;
  transition: all 0.15s;
  opacity: 0;
  cursor: pointer;

  &::-webkit-input-placeholder {
    color: $grey-manatee;
  }
  &::-moz-placeholder {
    color: $grey-manatee;
  }
  &:-ms-input-placeholder {
    color: $grey-manatee;
  }
  &:-moz-placeholder {
    color: $grey-manatee;
  }

  &:hover {
    & ~ .searchIcon {
      color: $grey-manatee;
    }
  }

  &:focus,
  &.notEmpty {
    width: 260px;
    padding: 10px 40px 10px 50px;
    opacity: 1;
    cursor: auto;
    box-shadow: -10px 0 10px 10px $header-color;

    & ~ .searchIcon {
      top: 12px;
      left: 22px;
      z-index: 2;
      width: 16px;
      color: $grey-manatee;
    }
  }

  &.notEmpty {
    & ~ .clearBtn {
      opacity: 1;
      pointer-events: auto;
      z-index: 2;
    }
  }
}

.searchIcon {
  color: #fff;
  position: absolute;
  top: 11px;
  left: 0;
  z-index: -1;
  transition: all 0.15s;
  pointer-events: none;
}

.clearBtn {
  display: flex;
  border: none;
  background: transparent;
  padding: 0;
  position: absolute;
  top: 11px;
  right: 11px;
  z-index: -1;
  opacity: 0;
  transition: all 0.15s;
  pointer-events: none;
  color: $grey-manatee;

  &:hover {
    color: $grey-storm;
  }
}
