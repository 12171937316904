@import '../../../../colors';

.box {
  display: flex;
}

.col {
  width: 76px;
  position: relative;

  & + .col {
    margin-left: 10px;
  }
}

.label {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  text-align: center;
  color: $grey;
  font-size: 12px;
  padding-bottom: 3px;
}

.input {
  padding: 5px 8px;
  font-size: 14px;
  height: 40px;
  border: none;
}
