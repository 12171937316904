@import '../../../colors';

.taskCard {
  height: 46px;
  padding: 0 15px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  color: $blue-zodiac;
  position: relative;

  &:hover {
    .headerActionsBox {
      opacity: 1;
    }
  }
}

.taskCard_content {
  overflow: hidden;
  white-space: nowrap;
  margin-right: 15px;
  height: 20px;
}

.headerActionsBox {
  position: absolute;
  right: 15px;
  top: -7px;
  z-index: 1;
  opacity: 0;
}

// to do
.ff000000-0000-0000-0000-000000000009 {
  background: linear-gradient(90deg, $status-to-do 25%, #ffffff 25%);
}
// assigned
.ff000000-0000-0000-0000-000000000001 {
  background: linear-gradient(90deg, $status-assigned 50%, #ffffff 50%);
}
// in progress
.ff000000-0000-0000-0000-000000000003 {
  background: linear-gradient(90deg, $status-in-progress 75%, #ffffff 75%);
}
// completed
.ff000000-0000-0000-0000-000000000008 {
  background: $status-completed;
}
