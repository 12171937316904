@import "../../../../colors";

.wrapper {
  padding: 15px;
  border-radius: 8px 8px 0 0;
}

.title {
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 10px;
  color: $midnight;
}

.row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.col {
  width: 47.3%;
}

.label {
  font-size: 10px;
  font-weight: 500;
  color: $midnight;
  padding-bottom: 4px;
}

.datepickerInput {
  height: 30px;
  font-size: 12px;
  border-radius: 4px;
  text-align: center;
  padding: 5px 10px;
}
