@import '../../../colors';

.item {
  background: $white;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #fff;

    .menu {
      opacity: 1;
      z-index: 1;
    }
    .percentBox {
      opacity: 0;
    }
  }

  &.notSelected {
    background: lighten($blue-hawkes, 4%);

    &:hover {
      background: lighten($blue-hawkes, 8%);
    }
  }

  &.isDropActive {
    background: $yellow-koromiko;
  }

  &.isTopicBoardView {
    margin: 0;

    &:hover {
      .percentBox {
        opacity: 1;
      }
    }

    .content {
      display: flex;
      align-items: center;
      min-height: 54px;
    }

    .avatarAndPercentRow {
      padding: 0 10px 0 0;
    }

    .footer {
      min-height: 30px;
    }
  }
}

.content {
  padding: 10px;
}

.avatarAndPercentRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.avatarBox {
  position: relative;
}

.avatar {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.typeIconBox {
  position: absolute;
  bottom: -4px;
  right: -4px;
  color: $midnight;
  background: $white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    flex-shrink: 0;
  }
}

.percentBox {
  color: $midnight;
  font-size: 10px;
  font-weight: 900;
  background: $yellow-koromiko;
  padding: 3px 5px;
  border-radius: 15px;
  min-width: 34px;
  text-align: center;
}

.name {
  font-size: 12px;
  font-weight: 500;
  color: $text-color;
  word-break: break-word;
  max-height: 34px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 9px;
  border-radius: 0 0 8px 8px;
  background: $blue-denim;
  color: $white;

  &.isObjective {
    background: $amulet-green;
    color: $blue-zodiac;
  }
}

.typeNameBox {
  padding-right: 3px;
  display: flex;
  align-items: center;
}

.typeName {
  font-size: 10px;
  font-weight: 600;
  padding-right: 5px;
}

.amount {
  font-size: 10px;
}

.menu {
  position: absolute;
  top: 12px;
  right: 7px;
  z-index: -1;
  opacity: 0;
}
