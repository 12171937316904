@import '../../../../colors';

.box {
  background: $blue-bunting;
  border-radius: 8px;
  padding: 20px;
  margin: 30px 30px 70px;
  height: 300px;
  position: relative;
}

.title {
  color: $grey-spindle;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}

.buttonsBox {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
}

.addBtn,
.findBtn {
  width: 47%;
  border: 1px solid #fff;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-radius: 8px;
  padding: 12px 10px;
  text-align: center;
  transition: all 0.15s;
}

.addBtn {
  border-color: transparent;
  background: #fff;
  color: $midnight;

  &:hover {
    background: $grey-whisper;
  }
}

.findBtn {
  background: transparent;
  color: #fff;

  &:hover {
    background: $midnight;
  }
}

.img {
  position: absolute;
  bottom: -40px;
  right: -5px;
}
