.videoBox {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}

.videoBg {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  color: #fff;

  img {
    margin-bottom: 40px;
  }
}
