.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  padding: 10px;
  z-index: 100;
}

.box {
  width: 100%;
  height: 100%;
  padding: 5px;
  border: 2px dashed #000;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.description {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
