.popoverBox {
  z-index: 500;
  overflow: visible !important;
}

.popoverOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.wrapper {
  width: 300px;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: #fff;
}
