@import '../../colors';

.wrapper {
  padding: 20px 12px;
  height: 100vh;
}

.container {
  padding: 30px;
  height: 100%;
  background: $white-smoke-2;
  border-radius: 8px;
  position: relative;
}

.closeBtn {
  position: absolute;
  top: 34px;
  right: 35px;
  background: transparent;
  display: flex;
  border: none;
  padding: 0;
  z-index: 10;
  color: $blue-zodiac;
  transition: all 0.15s;

  &:hover {
    color: $grey-storm;
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: $blue-zodiac;
  margin-bottom: 30px;
}

.inputBox {
  padding-bottom: 15px;
  position: relative;
}

.input {
  border: none;
  outline: none;
  border-radius: 8px;
  background: $grey-whisper-4;
  padding: 12px 50px;
  font-size: 12px;
  font-style: italic;
  line-height: 1.4;
  height: 40px;
  width: 100%;
  display: block;

  &:focus,
  &.notEmpty {
    & ~ .placeholderBox {
      left: 22px;

      .placeholder {
        opacity: 0;
      }
    }
  }

  &.notEmpty {
    & ~ .clearBtn {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.placeholderBox {
  display: flex;
  position: absolute;
  pointer-events: none;
  top: 12px;
  left: 40%;
  color: $grey-storm-2;
  transition: all 0.15s;
}

.placeholder {
  font-size: 12px;
  font-style: italic;
  transition: all 0.15s;
  padding-left: 10px;
}

.placeholderIconBox {
  line-height: 1;
}

.clearBtn {
  position: absolute;
  display: flex;
  top: 10px;
  right: 22px;
  border: none;
  background: transparent;
  padding: 0;
  color: $grey;
  opacity: 0;
  transition: all 0.15s;
  pointer-events: none;

  &:hover {
    color: $grey-nobel;
  }
}

.boxOverflow {
  overflow-y: auto;
  height: calc(100% - 110px);
  padding-right: 5px;
  margin-right: -5px;
}

.groupList {
  position: relative;
}

.groupItem {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-top: 1px solid $grey-gainsboro-2;

  &:nth-of-type(1) {
    border-top-width: 0;
  }
}

.groupAvatar {
  flex-shrink: 0;
  width: 40px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.groupData {
  flex-grow: 1;
  padding: 0 15px;
}

.groupName {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.6;
}

.groupMembersCount {
  font-size: 10px;
  font-weight: 600;
  line-height: 1.6;
  color: $grey-nobel;
}

.groupBtnBox {
  flex-shrink: 0;
}

.groupBtn {
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  min-width: 110px;
  height: 32px;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.4;
  letter-spacing: 0.1em;
  color: $blue-bunting;
  text-transform: uppercase;
  background: $btn-secondary;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: $btn-secondary--hover;
  }
}

.noFoundBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  min-height: 100%;
  overflow-y: auto;
  font-size: 24px;
  font-weight: 600;
  color: $blue-zodiac;

  img {
    display: block;
    margin-bottom: 25px;
  }
}
