@import '../../../colors';

.header {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerInfoCol {
  display: flex;
  align-items: center;
}

.headerGroupName {
  font-size: 18px;
  font-weight: 600;
  color: $blue-zodiac;
  word-break: break-word;
}

.editButtonsCol {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.cancelEditStateBtn,
.saveBtn {
  min-height: 32px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  margin-left: 15px;
}

.cancelEditStateBtn {
  background: $grey-gainsboro-3;

  &:hover {
    background: $grey-light;
  }

  &:disabled {
    background: $grey-gainsboro-3;
  }
}

.saveBtn {
  &:disabled {
    background: $grey-gainsboro-3;
  }
}

.closePageBtn {
  background: transparent;
  display: flex;
  border: none;
  padding: 0;
  color: $blue-zodiac;
  margin-left: 30px;

  &:hover {
    color: $grey-storm;
  }
}

.dropZoneWrapper {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  margin-right: 20px;
  outline: none;
  position: relative;
}

.dropZoneHover {
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.15s;
  border-radius: 50%;

  &.show {
    opacity: 1;
  }
}

.dropZoneBox {
  width: 100%;
  height: 100%;
  position: relative;

  &:not(.disabled):hover {
    cursor: pointer;

    .dropZoneHover {
      opacity: 1;
    }
  }
}

.dropZoneAvatar {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.mainFieldsBox {
  background: $white-smoke-4;
  padding: 30px 15px;
}

.mainFieldsContent {
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
}

.label {
  font-size: 10px;
  font-weight: 600;
  color: $midnight;
  padding-bottom: 4px;
}

.fieldBox {
  margin-bottom: 15px;
  position: relative;
}

.input {
  display: block;
  width: 100%;
  min-height: 50px;
  background: #fff;
  border: 1px solid $grey-gainsboro-2;
  border-radius: 8px;
  padding: 12px 40px 12px 20px;
  font-size: 14px;
  line-height: 1.6;
  color: $midnight;
  outline: none;
  text-overflow: ellipsis;

  &.error {
    border-color: $red-sunset;
  }

  &:disabled {
    background: transparent;
  }
}

.clearFieldBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  padding: 5px;
  line-height: 1;
  color: $blue-zodiac;

  &:hover {
    color: $grey-storm;
  }
}

.typeWrapper {
  border-top: 1px solid $grey-gainsboro-2;
  padding-top: 10px;
}

.typeBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.typeTitle {
  font-weight: 600;
  color: $midnight;
}

.typeDescription {
  font-size: 12px;
  font-style: italic;
  color: $grey-dim;
}

.checkboxItem {
  width: 68px;
  height: 40px;
  border-radius: 20px;
  background: #ffffff;
  border: 1px solid $grey-gainsboro-3;
  padding: 3px;
  position: relative;
}

.checkboxCircle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $grey-light;
  position: absolute;
  top: 7px;
  left: 8px;
  transition: all 0.15s;
}

.checkIcon {
  opacity: 0;
  position: absolute;
  top: 11px;
  left: 10px;
  color: #fff;
  transition: all 0.15s;
}

.checkboxPrivate {
  width: 100%;
  height: 100%;
  background: $yellow-koromiko;
  border-radius: 20px;

  .checkboxCircle {
    left: 35px;
    background: #fff;
  }

  .checkIcon {
    opacity: 1;
  }
}

.cropperWrapper {
  padding: 30px;
  background: $white-smoke-4;
}

.cropperBox {
  width: 100%;
  height: 300px;
}

.cropperButtonsBox {
  margin-top: 30px;
  display: flex;
  justify-content: center;

  & > button {
    max-width: 100px;
    min-height: 32px;
    margin: 0 5px;
  }
}

.closeCropperBtn {
  background: $grey-very-light;

  &:hover {
    background: $grey-silver;
  }

  &:disabled {
    background: $grey-very-light;
  }
}
