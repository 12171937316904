.notifications-dropdown {
  position: absolute;
  width: 290px;
  height: auto;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  top: 30px;
  z-index: 300;
  right: 0;
  cursor: default;
  overflow: hidden;
  border-radius: 5px;

  &::before {
    bottom: 100%;
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    border-width: 0 20px 8px 20px;
    border-color: transparent transparent #fff transparent;
    border-style: solid;
    right: 21px;
  }

  .body-block {
    overflow: hidden;
  }

  .arrow-block {
    width: 30px;
    height: 30px;
    background: #ffffff;
    position: absolute;
    top: -5px;
    right: 24px;
    transform: rotate(45deg);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    z-index: 2;
  }

  .body-block-all-n {
    max-height: calc(100vh - 130px);
    overflow: auto;
    padding-bottom: 2px;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .notification-body {
    line-height: 15px;
    word-break: break-word;
  }

  .notification-body-overlay {
    border-radius: 15px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    background: #fff;
  }

  .title-separator {
    color: #999;
    padding: 2px 10px;
    border-bottom: 1px solid #f3f3f3;
    position: relative;
    z-index: 4;
  }

  .no-unread-notifications {
    position: relative;
    z-index: 4;
    padding: 2px 8px;
    text-align: center;
    margin: 5px 0;
  }

  .show-all-notifications,
  .read-all {
    display: inline-block;
    color: rgb(56, 104, 160);
    padding: 2px 8px;
    border-radius: 7px;
    margin: 5px 0;
    font-size: 13px;
    cursor: pointer;
    border: none;
    outline: none;

    //&:hover {
    //  background: #6C98B9;
    //}
  }

  .notifications-loader {
    text-align: center;

    .loader-box {
      z-index: 3;
    }
  }
}
