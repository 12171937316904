@import '../../colors';

.wrapper {
  display: flex;
  max-width: 1130px;
  margin: 0 auto;
}

.content {
  flex-grow: 1;
  position: relative;
  height: 100vh;
  min-width: 1px;
}

.rightCol {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 120px;
  margin-right: 12px;
  padding-top: 20px;
  height: 100vh;
}

.defaultContent {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
