@import '../../../../colors';

.filesBox {
  padding-bottom: 12px;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
}

.fileItem {
  margin: 0 18px 3px 0;
  max-width: 100%;
}
