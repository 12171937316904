@import '../../../colors';

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: $blue-zodiac;
  margin: 0 0 20px;
}

.searchInputBox {
  padding-bottom: 20px;
}

.usersList {
  max-height: 220px;
  overflow-y: auto;
  margin-right: -5px;
  padding-right: 5px;
}

.userItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  min-height: 44px;
}

.userInfo {
  display: flex;
  align-items: center;
  min-width: 1px;
  padding-right: 15px;

  img {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}

.userName {
  font-size: 12px;
  color: $text-color;
  min-width: 1px;
  padding-left: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ownerRole {
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  color: $text-color;
}
