@import '../../../colors';

.actorItem {
  cursor: pointer;
  padding: 10px 27px 10px 10px;
  display: flex;
  align-items: center;
  margin-left: 40px;
  border-radius: 50px 0 0 50px;
  transition: all 0.4s;
  position: relative;

  &:hover {
    background: $sidebar--hover;

    .settingsBox {
      opacity: 1;
    }

    .notificationBtn {
      background: $cloud-burst;

      &:hover {
        background: $blue-zodiac;
      }
    }

    .actorName {
      color: #fff;
    }
  }

  &.isSettingsShown {
    background: $sidebar--hover;
  }

  &.active {
    background: $sidebar--active;

    .actorName {
      font-weight: 600;
      color: #fff;
    }

    &:hover {
      .notificationBtn {
        background: $blue-zodiac;

        &:hover {
          background: $blue-bunting;
        }
      }
    }
  }

  &.hasNotifications {
    .actorName {
      font-weight: 600;
      color: #fff;
    }
  }

  &.collapsedSidebar {
    justify-content: center;
    padding: 14px 0 14px 18px;
    margin: 0 5px;
    border-radius: 8px;

    &:hover {
      .notificationBtn {
        background: transparent;
      }
    }

    .notificationBtn {
      position: absolute;
      left: 0;
      top: 50%;
      margin: -12px 0 0;
      background: transparent;

      &:hover {
        color: $grey-silver;
      }
    }

    .settingsBox {
      margin-left: 8px;
    }

    .onlineLabel,
    .snoozedLabel {
      bottom: -3px;
      right: -3px;
    }

    .readyToTalkLabel {
      bottom: -4px;
      right: -12px;
    }

    .infoBox,
    .lock,
    .muteBox,
    .feedFilterLabel {
      display: none;
    }
  }
}

.avatarBox {
  flex-shrink: 0;
  position: relative;
  border-radius: 50%;
}

.avatar {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.lock {
  position: absolute;
  top: 5px;
  left: -25px;
  color: $grey-raven;
}

.muteBox {
  position: absolute;
  top: 7px;
  left: -42px;
  color: $grey-raven;
}

.feedFilterLabel {
  position: absolute;
  top: -8px;
  right: -11px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  color: $grey-raven;
  background: $sidebar-color;
}

.onlineLabel {
  position: absolute;
  bottom: -6px;
  right: -6px;
  width: 14px;
  height: 14px;
  background: $amulet-green;
  border: 3px solid $sidebar-color;
  border-radius: 50%;
}

.snoozedLabel {
  position: absolute;
  bottom: -6px;
  right: -6px;
  width: 14px;
  height: 14px;
  background: $yellow-koromiko;
  border: 3px solid $sidebar-color;
  border-radius: 50%;
  text-align: center;
  line-height: 1;
  color: $midnight;

  svg {
    position: absolute;
    top: 2px;
    left: 2px;
  }
}

.readyToTalkLabel {
  position: absolute;
  bottom: -7px;
  right: -15px;
  width: 24px;
  height: 16px;
  background: $amulet-green;
  border: 2px solid $sidebar-color;
  border-radius: 24px;
  line-height: 1;
  color: $midnight;

  svg {
    position: absolute;
    top: 1px;
    left: 4px;
  }
}

.infoBox {
  flex-grow: 1;
  margin-left: 15px;
  min-width: 1px;
}

.actorName {
  font-size: 12px;
  color: $grey-spindle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.allFeedIcon {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.03em;
  position: relative;
  background: $yellow-selective;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: 28px;
  height: 28px;
  color: #000;
  text-transform: uppercase;
  cursor: pointer;
}

.otherBox {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.notificationBtn {
  background: transparent;
  border: none;
  margin-left: 10px;
  padding: 5px 7px;
  border-radius: 20px;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.3;
  color: #fff;
  display: flex;
  align-items: center;
}

.notificationBox {
  display: flex;
  align-items: center;

  & + .notificationBox {
    padding-left: 8px;
  }

  & > svg {
    flex-shrink: 0;
    margin-right: 4px;
  }
}

.settingsBox {
  opacity: 0;
  align-items: center;
  margin-left: 12px;
  position: relative;
}

.settingsBtn {
  border: none;
  display: flex;
  padding: 0 3px;
  color: #fff;
  background: transparent;
  transition: all 0.15s;

  &:hover {
    color: $grey-silver;
  }
}

.popoverBox {
  overflow: visible !important;
  z-index: 100;
}

.popoverOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.settingsMenu {
  width: 160px;
  z-index: 20;
  cursor: default;
}
