.wrap {
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.loaderBox {
  text-align: center;
  padding-top: 10px;
}
