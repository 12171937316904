@import '../../../colors';

.box {
  height: 60px;
  background: #fff;
  border-radius: 8px;
  border: 1px dashed $grey-swan;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: $midnight;
  margin: 12px 0;

  &.isDropActive {
    background: $yellow-koromiko;
  }
}

.emptyWrap {
  height: 100%;
}

.emptyBox {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #e7eaf2;
  border: 1px dashed #aaacb3;
  border-top-width: 0;
  border-bottom-width: 0;
  padding: 7px;

  &.isDropActive {
    background: $grey-whisper;
  }
}

.emptyTitle {
  font-size: 16px;
  font-weight: 600;
  color: $blue-bunting;
  text-align: center;
  padding-bottom: 10px;
}

.emptyDescription {
  font-size: 10px;
  font-weight: 500;
  color: $grey;
  text-align: center;
}
