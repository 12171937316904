@import '../../../colors';

.popoverBox {
  z-index: 500;
  overflow: visible !important;
}

.popoverOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.wrap {
  width: 240px;
  padding: 18px;
  background: $white;
  border-radius: 8px;
  max-height: 100vh;
  overflow-y: auto;
  box-shadow: 5px 5px 24px rgba(0, 0, 0, 0.15);
  color: $blue-zodiac;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.avatarBox {
  img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
  }
}

.buttonsCol {
  display: flex;
  align-items: center;

  button {
    flex-shrink: 0;
    background: transparent;
    border: none;
    padding: 0;
    color: $blue-zodiac;
    margin-left: 25px;

    &:hover {
      color: $grey-storm-2;
    }
  }
}

.topicType {
  display: flex;
  align-items: center;
  color: $midnight;
  font-size: 12px;
  font-weight: 600;
  padding-top: 15px;

  svg {
    margin-right: 7px;
  }
}

.topicName {
  font-size: 20px;
  font-weight: 500;
  padding-top: 7px;
  word-break: break-word;
}

.targetDateBox {
  font-size: 12px;
  padding-top: 8px;
}

.targetDate {
  font-weight: 600;
}

.creatorBox {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.creatorAvatarBox {
  padding-right: 10px;

  img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
  }
}

.creatorText {
  font-size: 12px;
  word-break: break-word;
  color: $midnight;
}

.creatorName {
  font-weight: 600;
}

.description {
  padding-top: 5px;
  word-break: break-word;
}

.goalLabel {
  font-size: 10px;
  font-weight: 600;
  padding: 20px 0 5px;
}

.objectiveGoal {
  word-break: break-word;
}

.progressBox {
  background: $white-smoke-2;
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;
}

.progressLabel {
  font-size: 10px;
  font-weight: 600;
  padding-bottom: 10px;
}

.percentBox {
  border: 1px solid $yellow-koromiko;
  border-radius: 4px;
  text-align: center;
  padding: 5px 5px;
  font-weight: 900;
  position: relative;
  z-index: 1;
}

.percentLine {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  background: $yellow-koromiko;
}

.infoIconBox {
  line-height: 1;
}

.infoIcon {
  margin-top: -2px;
}

.hoverBox {
  position: relative;

  &:hover {
    .hoverProfileBox {
      display: block;
    }
  }
}

.hoverProfileBox {
  margin-left: -280px;
  padding-right: 40px;
  position: fixed;
  z-index: 500;
  display: none;

  &.isObjective {
    margin-left: -300px;
    padding-right: 60px;
  }
}
