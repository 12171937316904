@import '../../../../colors';

.item {
  color: $blue-zodiac;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
    color: $grey-dim;
  }
}

.icon {
  flex-shrink: 0;
}

.name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 1px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
}
