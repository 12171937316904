.button {
  border-radius: 50%;
  border: 2px solid #b2b2b2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &__selected {
    border-color: #42679c;
  }

  &_dot {
    width: 50%;
    height: 50%;
    border-radius: 50%;
    display: block;
    background-color: #42679c;
    visibility: hidden;
  }
}
