@import "../../../../colors";

.wrapper {
  padding: 15px;
  background: $white-smoke-2;
  border-radius: 8px 8px 0 0;
}

.title {
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 10px;
  color: $midnight;
}
