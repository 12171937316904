@import '../../../colors';

.btn {
  background: transparent;
  border: none;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  transition: all 0.15s;

  &:hover {
    color: $grey-manatee;
  }
}

.count {
  background: $red-sunset;
  position: absolute;
  top: -12px;
  right: -4px;
  transform: translateX(50%);
  min-width: 24px;
  height: 24px;
  border: 3px solid $header-color;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  font-weight: 600;
  color: #fff;
  font-size: 11px;
  padding: 0 3px;
}
