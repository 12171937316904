@import '../../../colors';

.menuWrap {
  position: relative;
  z-index: 2;
}

.menuBtn {
  display: block;
  width: 15px;
  height: 15px;
  background: transparent;
  border: none;
  padding: 0;
  line-height: 1;
  color: $midnight;

  &:hover {
    color: $grey-storm-2;
  }
}

.menuBox {
  width: 100px;
  position: absolute;
  right: -3px;
  top: 100%;
  margin-top: 6px;
  display: block;

  &.hidden {
    overflow: hidden;
    display: none;
  }
}
