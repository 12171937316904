@import '../../../colors';

.taskReactions {
  padding: 20px;
  z-index: 100;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  position: absolute;
  width: 180px;
  background-color: #ffffff;
  box-shadow: 0 0 52px rgba(0, 0, 0, 0.35);
}

.taskReactionsHeader {
  display: flex;
  padding-bottom: 10px;
  justify-content: space-between;
}

.taskReactionsTitle {
  font-size: 18px;
  font-weight: bold;
  color: $midnight;
}

.taskReactionsContent {
  overflow: hidden;
  overflow-y: scroll;
  height: 300px;
}

.taskReactionsContent_empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $blue-zodiac;
}

.taskReactionsItem {
  padding: 9px 0 10px;
}
.taskReactionsItem_header {
  display: flex;
}
.taskReactionsItem_left {
  width: 34px;
}
.taskReactionsItem_actionBar {
  display: flex;
  flex-direction: row;
}
.taskReactionsItem_bordered {
  border-top: 1px solid #d9d9d9;
}

.taskReactionsItem_action {
  font-size: 10px;
  font-weight: bold;
  align-items: center;
  display: flex;
}

.taskReactionsItem_time {
  font-size: 10px;
  color: #666666;
}

.taskReactionsItem_name {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  padding: 5px 0 7px;
  white-space: nowrap;
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.closeBtn {
  border: 0;
  background-color: transparent;
}
