@import '../../../colors';

.modal {
  padding: 30px;
}

.topicList {
  max-height: 200px;
  margin: 10px 0;
  overflow-y: auto;
}

.topicItem {
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    .addBtn {
      opacity: 1;
    }
  }
}

.topicName {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.addBtn {
  flex-shrink: 0;
  margin-left: 10px;
  padding: 5px;
  width: 110px;
  min-height: 32px;
  opacity: 0;
}

.emptyTopicList {
  padding: 5px 30px;
  font-size: 12px;
  text-align: center;
}

.createBtn {
  width: 83%;
  margin: 15px auto 0;
}
