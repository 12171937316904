@import '../../colors';

.wrapper {
  background: #fff;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.container {
  max-width: 400px;
  margin: 0 auto;
}

.header {
  padding: 30px;
  color: $blue-zodiac;
}

.descriptionBox {
  background: url('./assets/bg.svg') no-repeat top right;
  min-height: 257px;
}

.description {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: $text-color;
  padding: 55px 15px;

  a {
    color: $blue-denim;
  }
}

.footer {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    flex-shrink: 0;

    & + a {
      margin-left: 20px;
    }
  }
}
