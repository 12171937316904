.Avatar {
  &-container {
    font-size: 0;
    line-height: 1;
  }

  &-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    transition: 0.5s;
  }
}
