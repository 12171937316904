@import '../../../colors';

.deleteImageBox {
  text-align: center;
  padding: 20px 0;
}

.trashBtn {
  background: transparent;
  border: none;
  padding: 0;
  color: $blue-zodiac;

  &:hover {
    color: $midnight;
  }
}
