@import "../../colors";

.nickNameBox {
  position: relative;
}

.nickNameLoader {
  position: absolute;
  bottom: 17px;
  right: -30px;
}

.policyBox {
  padding-top: 40px;
  font-size: 14px;

  a {
    color: $blue-denim;

    &:hover {
      color: $grey-raven;
    }
  }
}

.submitBox {
  padding-top: 15px;
}
