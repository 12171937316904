@import '../../../../../colors';

.btnsBox {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;

  & > button {
    min-width: 40px;
    min-height: 27px;
    background: #fff;
    color: $grey-nobel;
    border: 1px solid $grey-gainsboro;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    margin-left: -1px;
    position: relative;
    z-index: 1;

    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover {
      color: $blue-lochmara;
      border-color: $blue-lochmara;
      z-index: 2;
    }
  }
}
