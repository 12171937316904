.wrapper {
  padding: 11px 11px 1px;
}

.header {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.avatarCol {
  flex-shrink: 0;
  width: 55px;
}

.nameAndDataCol {
  min-width: 1px;
}

.imgAndDescBox {
  display: flex;
  align-items: flex-start;
}
