@import '../../../colors';

.listWrapper {
  &.hidden {
    display: none;
  }
}

.box {
  padding: 10px 0;
}

.callBtn {
  border-radius: 6px 6px 0 0;
}

.callParticipantsBtnBox {
  button:not([class*='_']) {
    font-size: 10px;
    margin-top: -10px;
    padding: 5px 15px 10px 50px;
    background: $white-smoke-3;
  }
}
