@import '../../../colors';

.firstLoaderBox {
  padding: 13px;
}

.callCommentsBox {
  //height: 529px;
  display: flex;
  flex-direction: column;
}
