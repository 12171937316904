@import '../../../../colors';

.comment {
  display: flex;
  align-items: flex-start;
  padding: 5px 12px;
  position: relative;

  &:hover {
    background-color: $white-smoke;

    .menu {
      opacity: 1;
      z-index: 2;
    }

    .dateBox {
      opacity: 0;
    }
  }

  &.editingView {
    padding-top: 5px;
    background-color: $white-smoke;
  }

  &.withAvatar {
    .dateBox,
    .commentContent {
      padding-top: 4px;
    }
  }

  :global(.user-mention) {
    color: $blue-lochmara;
  }
}

.avatarBox {
  flex-shrink: 0;
  width: 34px;
  display: flex;
}

.avatar {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  &:hover {
    opacity: 0.85;
  }
}

.dateBox {
  flex-shrink: 0;
  padding-left: 5px;
}

.loaderBox {
  display: flex;
  flex-shrink: 0;
  padding-left: 5px;
}

.commentDate {
  font-size: 10px;
  color: $grey-dim;
}

.commentContent {
  flex-grow: 1;
  min-width: 1px;
}

.userInfo {
  padding-bottom: 5px;
}
.userName {
  font-size: 13px;
  font-weight: 600;
  color: $text-color;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > div {
    display: inline-block;

    &:hover {
      color: $grey-storm;
    }
  }
}

.commentBody {
  font-size: 13px;
  word-break: break-word;

  p {
    white-space: pre-wrap;

    &:nth-last-of-type(1) {
      display: inline;
    }
  }

  &.lg {
    font-size: 26px;
  }

  :global(.hashtagItem) {
    color: $blue-denim;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      color: $grey-dim;
    }
  }
}

.linkPreviewItem {
  margin: 10px 0 0;
}

.labelEdited {
  color: $grey-nobel;
  font-size: 12px;
}

.menu {
  position: absolute;
  top: -4px;
  right: 7px;
  opacity: 0;
  z-index: -1;
}

.reactionsList {
  padding-top: 3px;
}
