@import '../../../../colors';

.wrapper {
  position: relative;
}

.input {
  background: transparent;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.successInfo {
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: 10px;
  font-size: 11px;
  background: $white;
  border-radius: 8px;
  padding: 9px 15px;
  box-shadow: 0 2px 11px rgba(0, 0, 0, 0.2);
}
