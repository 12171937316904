@import "../../../colors";

.wrapper {
  background: $yellow-koromiko;
  height: 100vh;
  overflow-y: auto;
}

.container {
  max-width: 750px;
  margin: 0 auto;
  padding: 160px 15px;
}

.logoBox {
  text-align: center;
  color: $midnight;
  padding-bottom: 160px;
}

.errorText {
  font-size: 24px;
  line-height: 1.6;
  text-align: center;
  color: $midnight;
}

.btnBox {
  padding-top: 90px;
  display: flex;
  justify-content: center;
}

.websiteBtn {
  background: #fff;
  width: auto;
  padding-right: 50px;
  padding-left: 50px;

  &:hover {
    background: rgba(255, 255, 255, 0.9);
  }
}
