@import '../../../../colors';

.btn {
  padding: 0;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  color: $midnight;
  font-size: 12px;
  font-weight: 600;

  &:hover {
    color: $grey-storm;

    .iconBox {
      opacity: 0.8;
    }
  }

  &.disabled {
    opacity: 1;
    color: $grey-silver;

    .iconBox {
      background: $grey-silver;
    }
  }

  &.lg {
    padding: 10px 15px;
    width: 100%;
  }

  &.grey {
    background: $white-smoke-3;
  }
}

button.btn.disabled {
  cursor: default;
}

.iconBox {
  flex-shrink: 0;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  &.sm {
    width: 24px;
    height: 24px;

    .iconPhone {
      width: 9px;
    }
  }

  &.md {
    width: 32px;
    height: 32px;

    .iconPhone {
      width: 12px;
    }
  }

  &.lg {
    width: 44px;
    height: 44px;

    .iconPhone {
      width: 16px;
    }
  }

  &.green {
    background: $amulet-green;
  }

  &.grey {
    background: $grey-dim;
  }

  &.orange {
    background: $yellow-koromiko;
  }
}

.icon {
  line-height: 1;
  width: 100%;
}

.text {
  padding-left: 10px;
}
