@import '../../../colors';

.box {
  display: flex;
  cursor: pointer;
  position: relative;

  &:hover {
    .tooltipWrapper {
      display: block;
    }
  }
}

.item {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background: $white-smoke-2;
  border-radius: 50%;
  width: 24px;
  height: 24px;

  & + .item {
    margin-left: -8px;
  }

  &.dots {
    font-size: 10px;
    font-weight: 600;
    border: 2px solid $white-smoke-2;
    background: $grey-whisper;
  }
}

.avatar,
.IMGroupIcon {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: block;
}

.IMGroupIcon {
  color: $grey-spindle;
}

.tooltipWrapper {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 5;
  padding-top: 7px;
  display: none;
}

.tooltipBox {
  background: $white;
  padding: 10px 10px 0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 150px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    right: 5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 5px 7px;
    border-color: transparent transparent $white transparent;
  }
}

.listItem {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.listItemAvatar {
  flex-shrink: 0;
  width: 16px;
  margin-right: 10px;
}

.listItemName {
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 1px;
}
