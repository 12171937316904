@import '../../../colors';

.wrapper {
  width: 70px;
  padding: 30px 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  height: 100px;
  background: $yellow-koromiko;
  flex-shrink: 0;
  border-top-right-radius: 15px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: $midnight;

  &:hover {
    color: $blue-zodiac;
  }
}

.content {
  background: $white;
  flex-grow: 1;
  min-width: 1px;
  min-height: 1px;
  overflow-y: auto;
  border-bottom-right-radius: 15px;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > li {
    padding: 10px 0;
    text-align: center;

    & > a {
      display: inline-block;
      padding: 10px;
      color: $grey;

      &:hover {
        color: $yellow-koromiko;
        text-decoration: none;
      }

      &.active {
        color: $midnight;
        cursor: default;
      }
    }
  }
}
