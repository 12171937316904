@import '../../../colors';

.content {
  padding: 30px 30px 20px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 25px 30px 0;
  color: $blue-zodiac;
}

.buttonsBox {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  button {
    width: 48%;
  }
}

.rejectBtn {
  background: $grey-very-light;

  &:hover {
    background: $grey-silver;
  }

  &:disabled {
    background: $grey-very-light;
  }
}
