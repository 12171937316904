@import '../../../colors';

.busyModal {
  background: #2f3545;
  border-radius: 20px;
}

.busyModalWrap {
  padding: 40px 30px;
}

.busyModalImg {
  max-width: 100%;
}

.busyModalText {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  padding: 50px 0 15px;
}

.busyModalCloseBtn {
  color: $grey-manatee;
}
