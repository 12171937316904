@import '../../../colors';

.box {
  position: fixed;
  display: flex;
  z-index: 10000;
  background: #fff;
  box-shadow: 0 20px 50px rgba(29, 36, 55, 0.5);
  transition: all 0.15s;
  border-radius: 8px;
  overflow: hidden;

  &.lg {
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  &.md {
    right: calc(50% - 450px);
    top: calc(50% - 300px);
    width: 900px;
    //height: 600px;

    &.isCommentsOpen {
      right: calc(50% - 600px);
      width: 1200px;
    }
  }

  &.sm {
    right: 25px;
    bottom: 25px;
    width: 300px;
    //height: 210px;
  }
}

.header {
  position: relative;

  &.sm {
    .draggableHeader {
      padding: 5px 12px;
      cursor: pointer;
    }

    .userInfo {
      height: 30px;
    }

    .userAvatar {
      img {
        width: 30px;
        height: 30px;
      }
    }

    .postTitleBox {
      display: none;
    }
  }
}

.dragBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.draggableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  background: #fff;
  position: relative;
}

.userInfo {
  display: flex;
  align-items: center;
  height: 40px;
}

.userAvatar {
  margin-right: 20px;
  position: relative;

  img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.toggleCommentsBtn {
  position: relative;
  flex-shrink: 0;
  border: none;
  background: transparent;
  padding: 0;
  color: $blue-zodiac;

  &:hover {
    color: $grey-storm;
  }
}

.readyToTalkLabel {
  position: absolute;
  bottom: -5px;
  right: -15px;
  width: 24px;
  height: 16px;
  background: $amulet-green;
  border: 2px solid #fff;
  border-radius: 24px;
  line-height: 1;
  color: $midnight;

  svg {
    position: absolute;
    top: 1px;
    left: 4px;
  }
}

.userName {
  font-size: 12px;
  font-weight: 600;
  color: $blue-zodiac;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
}

.postTitleBox {
  position: relative;
  min-width: 1px;
  padding: 0 20px;
}

.buttonsBox {
  display: flex;
  align-items: center;
  position: relative;
}

.btnSize {
  flex-shrink: 0;
  background: transparent;
  border: none;
  padding: 0;
  color: $blue-zodiac;
  line-height: 1;

  &:hover {
    color: $grey-storm;
  }

  & + .btnSize {
    margin-left: 20px;
  }
}

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;

  &.lg {
    height: 100%;
  }

  &.md {
    height: 600px;
  }

  &.sm {
    height: 210px;
  }
}

.commentsBox {
  flex-shrink: 0;
  width: 320px;
  background: $white-smoke;

  &.lg {
    height: 100%;
  }

  &.md {
    height: 600px;
  }

  &.sm {
    display: none;
  }

  &.isCommentsHidden {
    display: none;
  }
}

.videoBox {
  height: 100%;
  flex-grow: 1;
  position: relative;
  background: #2f3545;
  display: flex;
  pointer-events: none;

  &.autoPointerEvents {
    pointer-events: auto;
  }

  &.sm {
    .videoBgImg {
      width: 80px;
    }

    .videoBgText {
      font-size: 16px;
      padding-top: 15px;
    }
  }
}

.videoBg {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.videoBgImg {
  display: block;
  margin: 0 auto;
}

.videoBgText {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  padding-top: 40px;
  white-space: nowrap;
  color: #fff;
}

.video {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative;
}

.dragBtn {
  width: 20px;
  height: 10px;
}
