@import '../../../../colors';

.box {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $midnight;
  background: transparent;
  padding: 0;
  border: none;
  transition: all 0.15s;
  margin: 2px 22px 7px 0;
  position: relative;

  &:hover {
    color: $grey-dim;
  }
}

.plus {
  position: absolute;
  top: -6px;
  right: -6px;
  border: 2px solid $card-bg;
  border-radius: 50%;
}

.allReactionsBox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

.popoverBox {
  z-index: 500;
  overflow: visible !important;
}

.popoverOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
