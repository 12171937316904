@import '../../../../colors';

.popoverBox {
  z-index: 500;
  overflow: visible !important;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
}

.btn {
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
  border: none;
  color: $blue-zodiac;
  border-radius: 8px;
  padding: 6px 20px;
  margin: 0 10px 5px 0;
  max-width: 300px;
  height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: $btn-secondary;

  &:hover {
    color: $blue-zodiac;
    text-decoration: none;
    background: $btn-secondary--hover;
  }

  &.disabled,
  &:disabled {
    background: $grey-gainsboro;
  }

  &.error {
    background: $grey-spindle;
  }

  &.dotsBtn {
    min-width: 44px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    cursor: default;

    &:hover {
      background: $btn-secondary;
    }
  }
}

.callBtn {
  margin: 0;
}

.callBtnBox {
  margin: 0 10px 5px 0;
}
