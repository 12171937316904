.notification {
  display: flex;
  border-bottom: 1px solid #f3f3f3;
  padding: 6px 10px;
  font-size: 11px;
  position: relative;
  z-index: 4;
  cursor: pointer;
  color: #35383e;

  &-not-seen {
    background-color: rgba(229, 243, 255, 0.9);
  }

  &:not(.date-divider):hover {
    background-color: #D9E5F0;
  }

  & .date-divider:hover {
    //background: rgba(197,219,235, 0.7);
    background-color: unset;
  }

  &-img {
    margin-right: 10px;
    display: flex;

    img {
      width: 24px;
      height: 24px;
      position: relative;
      top: 3px;
      border-radius: 50%;
    }
  }

  &-title {
    font-size: 13px;
    font-weight: 600;
  }

  &-time {
    color: #999;
  }

  &-date {
    color: rgb(110, 126, 144);
    font-size: 12px;
    font-weight: 600;
  }
}
