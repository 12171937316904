@import '../../../colors';

.react-datepicker {
  font-family: inherit;
}

.react-datepicker-wrapper {
  display: block;
  width: 100%;
}

.react-datepicker__day {
  &:hover {
    background: $yellow-koromiko;
  }
}

.react-datepicker__day--in-range,
.react-datepicker__day--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: $yellow-koromiko;

  &:hover {
    background: $yellow-koromiko;
  }
}

.react-datepicker__day--selected {
  background: $yellow-koromiko;
  color: #000;
  font-weight: 600;
}

.react-datepicker__day--in-selecting-range {
  background: rgba($yellow-koromiko, 0.5);

  &:hover {
    background: $yellow-koromiko;
  }
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: auto;
}
