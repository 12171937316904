@import '../../colors';

.loaderBox {
  width: 100%;
  text-align: center;
}

.emptyBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  font-size: 16px;
  font-weight: 600;
  font-style: italic;
}

.colHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  color: $midnight;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  height: 50px;
  border-radius: 7px 7px 0 0;
  border-bottom: 10px solid $grey-storm;

  // to do
  &.ff000000-0000-0000-0000-000000000009 {
    border-bottom-color: $status-to-do;
  }
  // assigned
  &.ff000000-0000-0000-0000-000000000001 {
    border-bottom-color: $status-assigned;
  }
  // in progress
  &.ff000000-0000-0000-0000-000000000003 {
    border-bottom-color: $status-in-progress;
  }
  // completed
  &.ff000000-0000-0000-0000-000000000008 {
    border-bottom-color: $status-completed;
  }
  // approved
  &.ff000000-0000-0000-0000-000000000002 {
    border-bottom-color: $status-approved;
  }
}
