@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Roboto-Regular.eot');
  src: url('./assets/fonts/Roboto-Regular.woff') format('woff'),
    url('./assets/fonts/Roboto-Regular.ttf') format('truetype'),
    url('./assets/fonts/Roboto-Regular.svg') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/Roboto-Italic.eot');
  src: url('./assets/fonts/Roboto-Italic.woff') format('woff'),
    url('./assets/fonts/Roboto-Italic.ttf') format('truetype'),
    url('./assets/fonts/Roboto-Italic.svg') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/Roboto-Bold.eot');
  src: url('./assets/fonts/Roboto-Bold.woff') format('woff'),
    url('./assets/fonts/Roboto-Bold.ttf') format('truetype'),
    url('./assets/fonts/Roboto-Bold.svg') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Roboto-Medium.eot');
  src: url('./assets/fonts/Roboto-Medium.woff') format('woff'),
    url('./assets/fonts/Roboto-Medium.ttf') format('truetype'),
    url('./assets/fonts/Roboto-Medium.svg') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 600;
  src: url('./assets/fonts/Roboto-BoldItalic.eot');
  src: url('./assets/fonts/Roboto-BoldItalic.woff') format('woff'),
    url('./assets/fonts/Roboto-BoldItalic.ttf') format('truetype'),
    url('./assets/fonts/Roboto-BoldItalic.svg') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/Roboto-Black.eot');
  src: url('./assets/fonts/Roboto-Black.woff') format('woff'),
    url('./assets/fonts/Roboto-Black.ttf') format('truetype'),
    url('./assets/fonts/Roboto-Black.svg') format('svg');
}

@font-face {
  font-family: 'pfdintextcomppro';
  src: url('./assets/fonts/pfdintextcomppro-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Kalam';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Kalam-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Kalam';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/Kalam-Bold.ttf') format('truetype');
}
