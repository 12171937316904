@import '../../../colors';

.btn {
  position: absolute;
  bottom: 90px;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 38px;
  color: $midnight;
  border: 4px solid $blue-hawkes;
  border-right-width: 0;
  border-radius: 12px 0 0 12px;
  background: $yellow-koromiko;
  text-align: center;
  padding: 4px;
  margin: 0;

  &:hover {
    background: lighten($yellow-koromiko, 5%);
  }

  &.collapsedSidebar {
    bottom: -19px;

    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    display: block;
    margin: 0 auto;
  }
}
