@import '../../colors';

.clickArea {
  cursor: pointer;
}

.popoverBox {
  z-index: 500;
  overflow: visible !important;
}
