@import '../../../../colors';

.box {
  display: flex;
  align-items: center;
  line-height: 1;
  min-height: 60px;
  padding-right: 25px;

  & > button,
  & > div {
    flex-shrink: 0;
    margin-left: 19px;
    outline: none;

    &:nth-child(1) {
      margin-left: 0;
    }
  }

  div[tabindex]:focus {
    outline: 1px dotted $midnight;
  }
}

.btn {
  display: flex;
  padding: 0;
  border: none;
  color: $grey;
  background-color: transparent;
  transition: all 0.15s;

  &:hover {
    color: $blue-zodiac;
  }
}
