@import '../../../colors';

.box {
  background: $yellow-koromiko;
  padding: 22px;
  border-radius: 8px 8px 0 0;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  position: relative;

  &.collapsedSidebar {
    height: 76px;

    .userSettings {
      right: 0;
      left: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .workspaceRow,
    .userRow {
      display: none;
    }
  }
}

.logo {
  color: $midnight;
}

.workspaceRow {
  display: flex;
  align-items: center;
}

.workspaceInfo {
  padding: 0 20px 0 15px;
  flex-grow: 1;
  min-width: 1px;
}

.workspaceName {
  font-size: 16px;
  font-weight: 600;
  color: $text-color;
  word-break: break-word;
  max-height: 66px;
  overflow-y: auto;

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba($white, 0.25);
    }
  }
}

.userRow {
  display: flex;
  align-items: center;
  padding-top: 5px;
}

.userAvatarWrap {
  position: relative;

  &:hover {
    .userAvatarHover {
      opacity: 1;
    }
  }
}

.userAvatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.userAvatarHover {
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  color: $white;
  padding: 9px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.15s;
  border-radius: 50%;
}

.snoozedIcon {
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid $yellow-koromiko;
  background: $midnight;
  text-align: center;
  color: $yellow-koromiko;
  line-height: 1;

  svg {
    margin-top: -6px;
  }
}

.userInfo {
  padding: 0 30px 0 15px;
  flex-grow: 1;
  min-width: 1px;
}

.userName {
  font-size: 14px;
  font-weight: 600;
  color: $text-color;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userSettings {
  position: absolute;
  bottom: 30px;
  right: 20px;
}
