@import '../../colors';

.demoBtnWrap {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 110;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: $grey-dim;
    text-decoration: underline;
  }

  input {
    margin-right: 5px;
    cursor: pointer;
  }
}
