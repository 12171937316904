@import '../../colors';

.list {
  list-style: none;
  padding: 0;
  display: flex;

  li {
    padding-right: 10px;
    font-size: 11px;
    line-height: 1.4;
    color: $black-ebony;

    &:nth-last-of-type(1) {
      padding-right: 0;
    }

    code {
      color: $black-ebony;
      font-size: 9px;
      border: 1px solid $grey-gainsboro;
      background: $white-smoke;
      padding: 0 2px;
    }
  }
}
