@import '../../colors';

.content {
  padding: 30px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.headerBtnBox {
  display: flex;
  align-items: center;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: $blue-zodiac;
}

.editBtn,
.closeBtn {
  background: transparent;
  border: none;
  padding: 0;
  color: $blue-zodiac;
  transition: all 0.15s;
  margin-left: 25px;

  &:hover {
    color: $grey-storm;
  }
}

.cancelEditFormBtn,
.saveEditFormBtn {
  min-height: 32px;
  margin-left: 10px;
}

.cancelEditFormBtn {
  background: $grey-very-light;

  &:hover {
    background: $grey-silver;
  }
}

.avatarAndNameRow {
  display: flex;
  padding: 10px 0;
}

.nameRightBox {
  flex-grow: 1;
}

.avatarBox {
  flex-shrink: 0;
  padding-right: 30px;
  margin-bottom: 10px;
}

.avatarInfo {
  width: 70px;
  height: 70px;
}

.dropZoneWrapper {
  outline: none;
  width: 70px;
  height: 70px;
}

.dropZoneHover {
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 600;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.15s;
  border-radius: 50%;
}

.dropZoneBox {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  &:not(.isDisabled):hover {
    .dropZoneHover {
      opacity: 1;
      cursor: pointer;
    }
  }
}

.avatar {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.defaultAvatarBox {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: $grey-whisper-3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey-nobel;
}

.cropperBox {
  width: 410px;
  height: 300px;
  margin-bottom: 20px;
}

.cropperButtonsBox {
  display: flex;
  justify-content: space-between;

  & > button {
    width: 48%;
  }
}

.closeCropperBtn {
  background: $grey-very-light;

  &:hover {
    background: $grey-silver;
  }
}

.labelBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 10px;
  color: $text-color;
  margin-bottom: 5px;
}

.label {
  font-weight: 600;
}

.row {
  padding-bottom: 10px;
}

.input {
  min-height: 50px;

  &.error {
    border-color: $red-sunset;
  }
}

.btnBox {
  padding-top: 10px;
}

.webhookBox {
  padding-top: 20px;

  .input {
    color: $blue-denim;
    text-overflow: ellipsis;
  }
}

.inputBox {
  input {
    height: 50px;
  }
}
