.emptyBoxWrap {
  min-width: 200px;
  width: 100%;
  overflow-y: auto;
}

.emptyBox {
  width: 100%;
  padding: 70px 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;

  img {
    margin-bottom: 15px;
  }
}

.backArrowImg {
  width: 90px;
}

.backBtn {
  font-size: 8px;
  border-radius: 15px;
  min-height: 24px;
  width: auto;
  margin: 20px auto 0;
}

.popoverBox {
  z-index: 500;
  overflow: visible !important;
}

.colHeader {
  position: relative;

  &:hover {
    .colHeaderMenuBtn {
      opacity: 1;
    }
  }
}

.colHeaderMenuBtn {
  position: absolute;
  top: 7px;
  right: 7px;
  border: none;
  background: transparent;
  padding: 0 6px;
  opacity: 0;
}
