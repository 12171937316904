@import '../../../colors';

.wrapper {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.box {
  display: flex;
  background: $cloud-burst;
  border-radius: 25px;

  & + .box {
    margin-left: 10px;
  }
}

.groupCallMenu {
  position: relative;

  &:hover {
    .groupCallMenuList {
      display: block;
    }
  }
}

.groupCallMenuList {
  display: none;
  width: 110px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 13px;
  z-index: 500;
}

.tooltipArrow {
  content: '';
  position: absolute;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid $white;
  top: 6px;
  left: 50%;
  margin-left: -4px;
}

.arrowBtn {
  background: transparent;
  border: none;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: #fff;

  &:hover {
    color: $grey-manatee;
  }

  .iconBox {
    flex-shrink: 0;
    margin-top: -2px;
  }
}
