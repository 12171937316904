@import '../../../colors';

.dropdown {
  width: 166px;
}

.arrow {
  position: absolute;
  top: 12px;
  right: 15px;
}

.resumeItem {
  button:not([class*='_']) {
    color: #76b36b;
    font-weight: 600;
  }
}
