@import '../../../../colors';

.header {
  color: $grey;
  padding-bottom: 10px;
  font-size: 13px;
}

.item {
  margin-bottom: 10px;
  background: $white;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;

  .row {
    padding: 10px 0;
    min-height: 60px;
  }
}

.row {
  display: flex;
  align-items: center;

  & > div {
    padding-right: 20px;

    &:nth-of-type(1) {
      padding-left: 20px;
    }
  }
}
