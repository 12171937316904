@import '../../../colors';

.wrapper {
  height: 100vh;
  position: relative;
  background: $blue-bunting;
  padding-top: 80px;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  padding: 27px;
  background: $yellow-koromiko;
  border-radius: 0 0 8px 8px;
  text-align: center;

  a {
    color: $midnight;

    &:hover {
      color: $cloud-burst;
    }
  }
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.formBox {
  width: 100%;
  max-width: 340px;
  padding: 0 15px;
  overflow-y: auto;
}

.label {
  color: $white;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 5px;
}

.inputBox {
  padding-bottom: 10px;
}

.errorBox {
  width: 100%;
  max-width: 700px;
  text-align: center;
  color: $white;
  font-size: 32px;
  font-weight: 600;
  padding: 0 15px;
  overflow-y: auto;

  img {
    margin-bottom: 50px;
  }
}

.loaderBox {
  text-align: center;
}

.endCallBox {
  text-align: center;
  color: $white;
  font-size: 32px;
  font-weight: 600;
  padding: 0 15px;
}

@media all and (max-width: 767px) {
  .wrapper {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 50px;
  }

  .header {
    height: 50px;
    padding: 12px;
  }

  .errorBox {
    img {
      max-width: 120px;
    }
  }

  .errorBox,
  .endCallBox {
    font-size: 20px;
  }
}
