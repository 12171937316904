@import '../../../colors';

.colName {
  flex-grow: 1;
  min-width: 120px;
  word-break: break-word;
}

.colPlan {
  flex-shrink: 0;
  width: 200px;
}

.colDate {
  flex-shrink: 0;
  width: 180px;
}

.colButtons {
  flex-shrink: 0;
  width: 270px;
}

.buttonsBox {
  display: flex;
}

.labelAPPROVED,
.labelREJECTED,
.labelREVOKED,
.btnApprove,
.btnReject {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 600;
  color: $white;
  border: none;
  padding: 10px;
  text-align: center;

  &:nth-of-type(1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:nth-last-of-type(1) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.btnApprove,
.btnReject {
  &:hover,
  &:disabled {
    opacity: 0.9;
  }
}

.labelAPPROVED {
  background: $yellow-koromiko;
}

.labelREJECTED,
.labelREVOKED {
  background: $grey-manatee;
}

.btnApprove {
  background: $amulet-green;
}

.btnReject {
  background: $red-sunset;
}
