.item {
  position: relative;
  padding: 0 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.animation {
  &:nth-of-type(1) {
    & > div {
      animation: slideInDownPost 1.5s ease;
    }
  }
}

.isDragging {
  opacity: 0.4;
}

@keyframes slideInDownPost {
  0% {
    margin-top: -100%;
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}
