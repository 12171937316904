@import '../../../../colors';

.box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.item {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
  color: $grey;
  height: 24px;
  padding: 0 11px;
  border: 1px solid $grey-gainsboro-2;
  border-radius: 15px;
  background: #fff;
  margin: 0 5px 5px 0;

  svg {
    flex-shrink: 0;
    color: $grey-swan;
    margin-right: 7px;
  }
}
