@import '../../../../../../colors';

.imageBox {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 1px;

  &.isCallView {
    .imageItem {
      cursor: default;
    }
  }
}

.imageItem {
  margin: 0 10px 10px 0;
  border-radius: 8px;
  background: $blue-alice;
  border: 1px solid $grey-whisper-2;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > div {
    min-height: 30px;
    min-width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    max-height: 200px;
    max-width: 300px;
    object-fit: contain;
  }
}
