@import '../../colors';

.content {
  padding: 30px;
  background: $white-smoke-2;
  border-radius: 8px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: $blue-zodiac;
  margin-bottom: 20px;
}

.description {
  color: $text-color;
  margin-bottom: 35px;
}

.labelBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 10px;
  font-weight: 600;
  color: $text-color;
  padding-bottom: 5px;
}

.row {
  padding-bottom: 10px;
}

.input {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 1.6;
  color: $text-color;
  padding: 12px 20px;
  background: #fff;
  border: 1px solid $grey-gainsboro-2;
  border-radius: 8px;
  min-height: 50px;
  outline: none;
  resize: none;

  &:disabled {
    color: $grey;
  }
}

.btnBox {
  padding-top: 10px;
}
