@import '../../../colors';

.wrapper {
  padding-bottom: 10px;
}

.label {
  font-size: 10px;
  font-weight: 600;
  color: $midnight;
  padding-bottom: 4px;
}

.fieldBox {
  position: relative;
}

.field {
  display: block;
  width: 100%;
  border: 1px solid $grey-gainsboro-2;
  border-radius: 8px;
  background: #fff;
  height: 50px;
  font-size: 14px;
  padding: 5px 15px;
  outline: none;
  text-overflow: ellipsis;

  &:focus {
    & + .passwordInfo {
      opacity: 1;
    }
  }

  &:disabled {
    background: $white-smoke;
  }

  &.error {
    border-color: $red-sunset;
  }
}

.passwordInfo {
  font-size: 13px;
  line-height: 1.2;
  color: $grey-dim;
  position: absolute;
  top: 2px;
  left: 100%;
  padding-left: 12px;
  width: 150px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s;
}

@media all and (max-width: 1199px) {
  .passwordInfo {
    position: relative;
    top: 0;
    left: 0;
    padding: 5px 0 0;
    width: 100%;
    display: none;
  }

  .field {
    &:focus {
      & + .passwordInfo {
        display: block;
      }
    }
  }
}
