@import '../../../colors';

$border-color: $grey-gainsboro-2;

.mainScreenWrapper {
  position: relative;
  background: #fff;
  border-radius: 8px;
  box-shadow: 10px 10px 24px rgba(0, 0, 0, 0.1);

  &.editMode {
    border-width: 0;
  }

  &.shadow {
    box-shadow: 0 0 99999px 99999px rgba(0, 0, 0, 0.3);
    z-index: 900;
  }

  [class*='__hashtag__'] {
    color: $blue-denim;
    font-weight: 600;
  }

  button:focus {
    outline: 1px dotted $midnight;
  }
}

.mainContent {
  position: relative;
  outline: none;
  padding-left: 60px;
}

.textFieldsRow {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.textFieldsCol {
  flex-grow: 1;
  min-width: 1px;
  padding: 18px 10px 19px 0;
  min-height: 60px;
  position: relative;
}

.actionButtonsCol {
  flex-shrink: 0;
  align-self: flex-end;
}

.footerRow {
  display: flex;
  border-top: 1px solid $border-color;
}

.postToCol {
  flex-grow: 1;
  min-width: 1px;
}

.combineUsersCol {
  flex-shrink: 0;
  padding: 10px;
}

.sendBtnCol {
  flex-shrink: 0;
  border-left: 1px solid $border-color;
}

.postToBox {
  display: flex;
}

.postToLabel {
  flex-shrink: 0;
  width: 60px;
  padding: 20px 15px 15px;
  font-size: 14px;
  font-weight: 600;
  color: $text-color;
}

.selectedBox {
  flex-grow: 1;
  min-width: 1px;
  padding: 5px 0;
}

.sendBtn {
  height: 100%;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white-smoke-3;
  border: none;
  border-bottom-right-radius: 8px;
  padding: 0;
  color: $text-color;
  min-height: 60px;

  &:hover {
    opacity: 0.85;
  }
}

.editHeader {
  background: $white-smoke-3;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid $border-color;
  padding: 12px 60px;
  font-size: 18px;
  font-weight: 600;
  color: $blue-zodiac;
}

.callBtn {
  color: #fff;
  background: $amulet-green;

  &:hover,
  &:disabled {
    color: #fff;
    background: lighten($amulet-green, 4%);
  }
}

.callBtn,
.editBtn {
  border-radius: 0 0 8px 8px;
}

.callBtnLoader,
.editBtnLoader {
  color: $blue-zodiac;
}
