@import '../../colors';

.passwordChangedBox {
  text-align: center;

  img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
}

.titleSuccess {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  color: $blue-zodiac;
  margin: 0 auto 40px;
  max-width: 300px;
}

.btnToLogin {
  margin: 0 auto;
  max-width: 300px;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.4;
  letter-spacing: 0.1em;
  color: $blue-bunting;
  text-transform: uppercase;
  background: $btn-secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 50px;

  &:hover {
    color: $blue-bunting;
    background: $btn-secondary--hover;
    text-decoration: none;
  }
}
