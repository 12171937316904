.box {
  position: relative;

  img {
    max-width: 300px;
    max-height: 300px;
  }

  &.isCallView {
    img {
      max-width: 150px;
    }
  }
}
