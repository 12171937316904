@import '../../../../colors';

.item {
  background: $white;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;

  &.isDragging {
    opacity: 0.7;
  }
}

.titleRow {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 12px;
  font-weight: 500;
  color: $midnight;
  word-break: break-word;
  max-height: 34px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}

.statusBox {
  padding-right: 3px;

  img {
    height: 20px;
    display: block;
  }
}

.dateRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 5px;
}

.dateAndStatusRow {
  display: flex;
  align-items: center;
}

.date {
  font-size: 10px;
  font-weight: 500;
  color: $grey-dim;
  margin-right: 6px;
  text-align: center;
  line-height: 1;

  &.shortFormat {
    max-width: 20px;
  }
}

.threadsAvatarBox {
  max-width: 100%;
}
