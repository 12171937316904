@import '../../colors';

.feed-wrapper {
  height: 100%;
  overflow-y: scroll;
  padding: 6px 0 5px 12px;
  margin-right: 7px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .loader {
    text-align: center;
  }
}

.IndexCard {
  margin-top: 1px;

  .ic-compact {
    position: relative;
  }

  .ic-expanded {
    display: none;
  }

  .service-post {
    display: block;
  }

  &.expanded .ic-expanded {
    display: flex;
  }

  &.expanded .ic-compact {
    display: none;
  }

  .line-divider {
    width: 2px !important;
    background: rgb(243, 243, 243);
  }
}

// Common styles

.ic-expanded:hover .ic-expanded-header .card-controls,
.ic-compact:hover .card-controls {
  opacity: 1;
  transition: all 0.3s ease;
}

.ic-compact .icard-counter-rate .icon,
.ic-expanded .icard-counter-rate .icon {
  background: url('../../assets/img/card-mail.png') no-repeat;
}
.ic-compact .icard-counter-link .icon,
.ic-expanded .icard-counter-link .icon {
  background: url('../../assets/img/card-comment.png') no-repeat;
}
.ic-compact .icard-counter-img .icon,
.ic-expanded .icard-counter-img .icon {
  background: url('../../assets/img/card-addimg.png') no-repeat;
}
.ic-compact .icard-counter-share .icon,
.ic-expanded .icard-counter-share .icon {
  background: url('../../assets/img/card-share.png') no-repeat;
}
.ic-compact .icard-counter-star .icon,
.ic-expanded .icard-counter-star .icon {
  background: url('../../assets/img/star.png') no-repeat;
}
.ic-compact .icard-time .icon,
.ic-compact .icard-counters .icon,
.ic-expanded .icard-counters .icon {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-size: contain;
  margin-right: 5px;
}

.ic-compact,
.ic-expanded {
  margin: 0 15px 15px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.2);
  cursor: default;
}

.ic-compact .icard-action-pie,
.ic-expanded-right .icard-action-pie {
  display: inline-block;
  height: 20px;
  width: 20px;
  background: url('../../assets/img/pieChar.png') no-repeat;
  background-size: contain;
  margin-right: 15px;
  cursor: pointer;
}
.ic-compact .icard-action-pie {
  height: 18px;
  width: 18px;
}

.ic-compact .card-controls,
.ic-expanded .ic-expanded-header .card-controls {
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  flex-direction: row;
  flex-grow: 1;
  color: #999;
  margin-left: 25px;
  position: relative;
  transition: all 0.3s ease;
}

.ic-compact .card-controls span,
.ic-compact .ic-expand-btn {
  font-size: 11px;
  cursor: pointer;
  margin: 0 7px;
  display: inline-block;
}

/*actions popup*/

.Taken-actions-modal .modal-body {
  position: relative;
  padding-top: 0;
}
.Taken-actions-modal .nav-tabs > li.active > a,
.Taken-actions-modal .nav-tabs > li.active > a:focus,
.Taken-actions-modal .nav-tabs > li.active > a:hover {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: none;
  border-bottom: 2px solid powderBlue;
  font-weight: 600;
}

.Taken-actions-modal {
  .modal-dialog {
    width: 700px;
  }

  .tab-pane > div {
    width: 50%;
  }

  .tab-pane {
    display: flex !important;
  }

  .nav-tabs {
    border-bottom: 2px solid #f6f6f6;
  }

  .ic-actions-taken-user {
    display: flex;
    padding: 15px 5px;
  }

  .ic-actions-taken-user > img {
    width: 45px;
    height: 45px;
  }

  .ic-actions-taken-user > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 10px;
  }

  .ic-actions-taken-user .user-action-time {
    font-size: 11px;
    color: #999;
  }

  .ic-actions-taken-user .user-name {
    font-size: 16px;
    font-weight: 600;
    color: #35383e;
  }

  .ic-actions-divider {
    font-size: 12px;
    display: block;
    border-top: 2px solid rgb(243, 243, 243);
    padding: 5px;
    color: #999;
    font-style: italic;
    font-weight: 600;
    width: calc(100% - 20px);
  }

  .ic-actions-charts .action {
    font-size: 11px;
    color: #999;
  }

  .ic-actions-charts {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 40px;
  }

  .ic-actions-charts > div {
    width: 66px;
    margin: 0 5px;
    text-align: center;
  }

  .bar1 .bar {
    margin: auto;
    width: 20px;
    height: 10px;
    background: #7fafd3;
  }

  .bar2 .bar {
    margin: auto;
    width: 20px;
    height: 1px;
    background: #7fafd3;
  }

  .bar3 .bar {
    margin: auto;
    width: 20px;
    height: 1px;
    background: #7fafd3;
  }
}

// new card animation

.example-enter {
  position: relative;
  transform: translateY(-100%);
  transition: all 0.5s ease-out;
}

.example-enter.example-enter-active {
  transform: translateY(0);
  transition: all 0.5s ease-out;
}
