@import '../../colors';

.wrapper {
  padding: 20px 12px 6px;
  flex: none;
}

.box {
  background: $header-color;
  border-radius: 8px;
  height: 80px;
  padding: 15px 30px;
  position: relative;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.colLeft {
  min-width: 1px;
}

.colRight {
  display: flex;
  align-items: center;
}

.actorInfo {
  display: flex;
  align-items: center;
}

.actorInfoCol {
  min-width: 1px;
}

.additionalInfo {
  display: flex;
  align-items: center;
}

.actorAvatarBox {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  margin-right: 30px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.joinBtnBox {
  padding-left: 60px;

  button {
    border: none;
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 900;
    line-height: 1.4;
    letter-spacing: 0.1em;
    color: $blue-bunting;
    text-transform: uppercase;
    background: $btn-secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 40px;
    min-width: 150px;

    &:hover {
      background: $btn-secondary--hover;
    }

    &:disabled {
      background: $btn-secondary--disabled;
    }
  }
}

.hiddenBtnBox {
  padding-left: 50px;
}

.generalBoxView {
  display: flex;
  align-items: center;
}

.btnMyFeed {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: transparent;
  border: none;
  padding: 0;
  max-width: 100%;
  transition: all 0.15s;

  &:hover {
    color: $grey-manatee;
  }
}

.btnMyFeedSmall {
  background: transparent;
  border: none;
  padding: 0;
  color: $grey-manatee;
  display: inline-flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  transition: all 0.15s;
  white-space: nowrap;

  &:hover {
    color: #fff;
  }
}

.arrowIcon {
  margin-right: 15px;
}

.btnActor {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: transparent;
  border: none;
  padding: 0;
  max-width: 100%;
  transition: all 0.15s;

  &:hover {
    color: $grey-manatee;
  }
}

.noveltyFilterLabel {
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $yellow-koromiko;
  padding: 6px 0 0 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
