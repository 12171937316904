.mentionItem {
  color: #3768a0;
}

.mentionSuggestions {
  border: 1px solid #eee;
  margin-top: 0.4em;
  position: fixed;
  z-index: 3;
  min-width: 220px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 1);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-transform: scale(0);
  transform: scale(0);
  max-height: 220px;
  overflow-y: auto;
}
