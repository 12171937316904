@import '../../../../colors';

.itemBox {
  margin-right: 15px;
  margin-bottom: 10px;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.itemMain {
  flex-grow: 1;
  background: $white-smoke-darken;
  overflow: hidden;
  transition: opacity 0.15s;

  &:hover {
    opacity: 0.85;
  }

  & > div {
    height: 100%;
  }
}

.itemAll {
  flex-shrink: 0;
  margin-left: 1px;
  width: 62px;
  height: 100%;
}

.itemRest {
  margin-bottom: 1px;
  height: 100%;
  background: $white-smoke-darken;
  overflow: hidden;
  transition: opacity 0.15s;

  &:hover {
    opacity: 0.85;
  }

  & > div {
    height: 100%;
  }
}

.itemView {
  width: 125px;
  height: 81px;
  display: flex;

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  &.two {
    .itemAll {
      width: 50%;
    }
  }
  &.three {
    .itemRest {
      height: 50%;

      .videoIcon {
        width: 24px;
        height: 24px;

        svg {
          width: 12px;
        }
      }
    }
  }
  &.more {
    .itemRest {
      height: 33.33%;

      .videoIcon {
        width: 18px;
        height: 18px;

        svg {
          width: 10px;
        }
      }
    }
  }
}

.labelMoreItem {
  position: absolute;
  bottom: -2px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding-bottom: 3px;
  width: 62px;
  height: 33.33%;
  background: rgba($midnight, 0.9);
  transition: all 0.15s;

  &:hover {
    background: rgba($midnight, 0.8);
  }
}

.videoPreview {
  width: 100%;
  height: 100%;
  position: relative;
}

.videoIconBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  color: $blue-zodiac;
  background: #fff;
  border-radius: 50%;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}
