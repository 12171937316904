@import '../../../colors';

.NoPosts {
  &-container {
    flex: 1;
    padding: 0 15px 15px;
    text-align: center;
  }

  &-title {
    font-size: 18px;
    padding: 67px 0;
  }

  &-image {
    user-select: none;
  }
}

.NoPosts-info {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  font-size: 16px;
  font-weight: 600;
  font-style: italic;
  min-height: calc(100vh - 250px);
}
