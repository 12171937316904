@import '../../../../colors';

.emoji-mart {
  border: none;
  margin: 0 auto;
  display: block;
  padding-top: 1px;

  svg {
    vertical-align: top;
  }
}

.emoji-mart-search {
  margin: 14px 14px 10px 13px;
  padding: 0;

  input {
    font-size: 12px;
    font-style: italic;
    line-height: 1.2;
    padding: 9px 30px 9px 15px;
    border-radius: 4px;
    border: none;
    color: $midnight;
    background: $white-smoke-3;
    height: 32px;
    font-family: 'Roboto', Arial, sans-serif;
  }
}

.emoji-mart-search-icon {
  right: 4px;
}

.emoji-mart-title-label {
  font-size: 22px;
}

.emoji-mart-anchor {
  &:hover,
  &.emoji-mart-anchor-selected {
    svg {
      fill: #ffb34c;
    }
  }
}

.emoji-mart-bar {
  display: none;
}

.emoji-mart-category-label {
  font-size: 13px;
  font-family: 'Roboto', Arial, sans-serif;
}

.emoji-mart-emoji-native {
  font-family: 'Roboto', Arial, sans-serif;

  &[aria-label*='relaxed'],
  &[aria-label*='white_frowning_face'] {
    display: none;
  }
}
