@import '../../../colors';

.divider {
  margin-bottom: 10px;

  button {
    border: none;
    background: $white;
    border-radius: 8px;
    display: block;
    width: 100%;
    text-align: left;
    color: $midnight;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
    position: relative;

    &:hover {
      background: rgba($white, 0.9);
    }

    &.active {
      background: $midnight;
      color: $white;

      &:hover {
        background: rgba($midnight, 0.9);
      }
    }
  }
}

.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  transform: rotate(-90deg);

  &.active {
    transform: rotate(0deg);
  }
}
