.feedback-wrapper {
  position: fixed;
  z-index: 10;
  bottom: 50px;
  right: 30px;
}

.show-feedback-btn {
  border: none;
  background: #d64658;
  color: #fff;
  font-size: 14px;
  line-height: 1.1;
  padding: 9px 11px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  outline: none;

  &:hover {
    background: #bf3f4f;
  }
}

.feedback-form {
  position: relative;
  width: 220px;
  background: #fef1c5;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.19);
  max-height: calc(100vh - 80px);
  overflow-y: auto;

  .close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    background: url('../../assets/img/x-red.png') no-repeat;
    width: 10px;
    height: 10px;
    padding: 0;
    border: none;
    outline: none;
  }

  .description {
    font-size: 13px;
    line-height: 1.5;
    padding: 10px 23px 10px 8px;

    i {
      font-style: italic;
    }
  }

  textarea {
    display: block;
    width: 100%;
    height: 165px;
    resize: none;
    border: none;
    font-size: 13px;
    line-height: 1.5;
    padding: 8px;
    outline: #0d2b3f;

    &::placeholder {
      color: #999999;
    }
  }

  .submit-box {
    padding: 15px;
  }
  .send-btn {
    width: 100%;
    background: #d64658;
    border: none;
    color: #fff;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 0.03em;
    padding: 10px;
    border-radius: 5px;
    outline: none;

    &:hover {
      background: #bf3f4f;
    }

    &:disabled {
      background: #d6465873;
    }
  }
}
