@import '../../../../../colors';

.dropzoneBox {
  outline: none;
}

.attachFileBtn {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  padding: 0;
  margin-right: 18px;
  height: 24px;
  color: $grey;

  &:not(:disabled):hover {
    color: $blue-zodiac;
  }
}
