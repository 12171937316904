@import '../../../colors';

.wrapper {
  margin-left: 30px;
}

.popoverBox {
  z-index: 500;
  overflow: visible !important;
}

.popoverBtn {
  background: transparent;
  border: none;
  padding: 0 6px;
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  transition: all 0.15s;

  &:hover,
  &:disabled {
    color: $grey-manatee;

    .popoverIconBox {
      background: $grey-manatee;
    }
  }
}

.popoverIconBox {
  position: absolute;
  top: -11px;
  right: -8px;
  z-index: 2;
  width: 24px;
  height: 24px;
  background: $white;
  border-radius: 50%;
  border: 3px solid $header-color;
  color: $midnight;
  text-align: center;
  transition: all 0.15s;

  svg {
    margin-top: -2px;
  }
}

.btnRow {
  display: flex;
  align-items: center;
  width: 100%;

  &.active {
    font-weight: 600;

    .checkIcon {
      opacity: 1;
    }
  }
}

.iconBox {
  flex-shrink: 0;
  width: 20px;
  margin-right: 10px;
  text-align: center;
}

.btnText {
  flex-grow: 1;
}

.checkIcon {
  flex-shrink: 0;
  opacity: 0;
  margin-left: 12px;
}
