@import '../../../colors';

.errorSubscriptionBox {
  background: $sidebar--active;
  padding: 6px;
  line-height: 1.3;
  border-radius: 6px;
  color: #fff;
  position: fixed;
  top: 50px;
  right: 18px;
  z-index: 10000;
}

.reloadBtn {
  display: inline-block;
  color: $white;
  background: $sidebar-color;
  font-size: 14px;
  padding: 3px 8px;
  border-radius: 6px;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  margin-top: 5px;

  &:hover {
    background: $sidebar--hover;
  }
}
