@import '../../../colors';

.btn {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  padding: 0;
  border: none;
  color: #64c255;
  background: $white-smoke-3;
  border-radius: 50%;
  transition: all 0.15s;

  &:hover {
    background: $white-smoke-2;
  }
}

.teamIcon {
  &.lg {
    width: 22px;
  }
}
