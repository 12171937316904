@import '../../../../../../colors';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 1px;
}

.item {
  padding: 0 20px 10px 0;
  max-width: 100%;
}
