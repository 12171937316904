@import '../../../colors';

.header {
  background: $midnight;
  height: 80px;
  border-radius: 8px 8px 0 0;
  padding: 28px 25px;
}

.logo {
  color: $yellow-koromiko;
}

.content {
  background: #fff;
  border-radius: 0 0 8px 8px;
  text-align: center;
  padding: 30px;
}

.avatarBox {
  padding-bottom: 20px;

  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
}

.userName {
  font-size: 18px;
  font-weight: 600;
  color: $blue-zodiac;
  padding-bottom: 5px;
}

.callText {
  font-size: 12px;
  font-weight: 600;
  color: $blue-zodiac;
}

.buttonsBox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;

  button + button {
    margin-left: 40px;
  }
}
