@import '../../../colors';

.box {
  position: relative;
}

.input {
  border: none;
  outline: none;
  border-radius: 8px;
  background: $grey-whisper-4;
  padding: 12px 50px;
  color: $text-color;
  font-size: 12px;
  font-style: italic;
  line-height: 1.4;
  height: 40px;
  width: 100%;
  display: block;

  &:focus,
  &.notEmpty {
    & ~ .placeholderBox {
      left: 22px;
      transform: translateX(0);

      .placeholder {
        opacity: 0;
      }
    }
  }

  &.notEmpty {
    & ~ .clearBtn {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.placeholderBox {
  display: flex;
  position: absolute;
  pointer-events: none;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  color: $grey-storm-2;
  transition: all 0.15s;
}

.placeholder {
  font-size: 12px;
  font-style: italic;
  transition: all 0.15s;
  padding-left: 10px;
}

.placeholderIconBox {
  line-height: 1;
}

.clearBtn {
  position: absolute;
  display: flex;
  top: 10px;
  right: 22px;
  border: none;
  background: transparent;
  padding: 0;
  color: $grey;
  opacity: 0;
  transition: all 0.15s;
  pointer-events: none;

  &:hover {
    color: $grey-nobel;
  }
}
