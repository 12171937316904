@import '../../../colors';

.btn {
  background: transparent;
  border: none;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: #fff;

  &.disabled,
  &:hover {
    color: $grey-manatee;
  }
}

button.btn.disabled {
  cursor: default;
}

.iconBox {
  flex-shrink: 0;
  margin-top: -2px;
}
