@import '../../colors';

.wrapper {
  padding: 20px 12px;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $white-smoke-2;
  border-radius: 8px;
  overflow-y: auto;
}

.header {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerInfoCol {
  display: flex;
  align-items: center;
}

.headerAvatarBox {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  margin-right: 20px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.headerGroupName {
  font-size: 18px;
  font-weight: 600;
  color: $blue-zodiac;
  word-break: break-word;
}

.headerButtonsCol {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.headerBtn {
  background: transparent;
  display: flex;
  border: none;
  padding: 0;
  color: $blue-zodiac;
  margin-left: 30px;

  &:hover {
    color: $grey-storm;
  }
}

.infoBox {
  background: $white-smoke-4;
  padding: 30px 15px 0;
  //padding: 30px 15px 60px;
}

.infoBoxContent {
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
}

.infoRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 600;
}

.privateLabel {
  color: $cloud-burst;
  padding-right: 15px;

  svg {
    margin: -4px 10px 0 0;
  }
}

.groupTopic {
  padding-top: 15px;
  color: $midnight;
  word-break: break-word;
  white-space: pre-wrap;
}

.callButtons {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  margin-bottom: -20px;
}

.callBtn {
  background: #fff;
  padding: 8px;
  border-radius: 50%;
  margin-left: 6px;
}
