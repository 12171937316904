@import '../../../../colors';

.description {
  flex-grow: 1;
  //padding-bottom: 10px;
  margin-bottom: 10px;
  word-break: break-word;
  font-size: 15px;
  line-height: 1.5;
  min-width: 1px;

  p {
    white-space: pre-wrap;
  }

  :global(.hashtagItem) {
    color: $blue-denim;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      color: $grey-dim;
    }
  }
}
