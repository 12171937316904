@import '../../../colors';

.mainRow {
  display: flex;
  border: 1px solid $grey-gainsboro-2;
  border-radius: 8px;
  background: #fff;

  &.error {
    border-color: $red-sunset;
  }
}

.contentCol {
  flex-grow: 1;
  min-width: 1px;
  padding: 5px 0 0 5px;
}

.btnCol {
  flex-shrink: 0;
  padding: 5px;
  position: relative;
}

.contactsWrapper {
  flex-grow: 1;
  min-height: 40px;
  min-width: 1px;

  :global(.react-autosuggest__container) {
    display: inline-block;
    vertical-align: top;
    position: relative;
  }

  :global(.react-autosuggest__suggestions-container) {
    position: absolute;
    z-index: 5;
    top: auto;
    bottom: 45px;
    left: 0;
    max-height: 124px;
    overflow-y: auto;
    box-shadow: 0 -3px 25px rgba(0, 0, 0, 0.15);
    background: #fff;
    width: 270px;
    border-radius: 8px;

    & > ul {
      padding: 0;
      list-style: none;
    }
  }

  :global(.react-autosuggest__suggestions-container--open) {
    border: 1px solid $grey-gainsboro-2;
  }

  :global(.react-autosuggest__suggestion--highlighted) {
    background: $blue-alice;
  }
}

.tagsWrapper {
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
}

.tagsInput {
  background: transparent;
  border: 0;
  color: #000;
  font-size: 14px;
  line-height: 1.4;
  outline: none;
  padding: 10px 5px;
  margin-bottom: 5px;
  height: 40px;
  width: 100px;
  cursor: pointer;
}

.contactItem {
  display: inline-flex;
  align-items: center;
  padding: 8px 10px;
  background: $grey-whisper-4;
  border-radius: 8px;
  margin: 0 5px 5px 0;
  position: relative;
  max-width: 170px;
}

.contactAvatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 10px;
}

.contactName {
  font-size: 12px;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $text-color;
}

.contactRemoveBtn {
  border: none;
  background: transparent;
  margin-left: 10px;
  line-height: 1;
  padding: 0;
  color: $blue-zodiac;

  &:hover {
    color: $grey-dim;
  }
}

.suggestionItem {
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px 15px;
  margin-bottom: 1px;

  &:hover {
    background: $blue-alice;
  }

  &.selected {
    background: $blue-alice;

    .itemName {
      color: $blue-lochmara;
    }
  }
}

.suggestionAvatar {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 50%;
}

.suggestionName {
  font-size: 12px;
  color: $text-color;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
}

.lightMode {
  border: none;
  background: transparent;

  .contentCol {
    padding-left: 0;
  }
}
