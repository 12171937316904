@import '../../../colors';

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 11px rgba(0, 0, 0, 0.2);

  & > li {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 15px;
      height: 1px;
      background: $grey-whisper-3;
    }

    &:nth-last-of-type(1) {
      &:after {
        display: none;
      }
    }
  }

  button:not([class*='_']),
  a:not([class*='_']) {
    display: flex;
    align-items: center;
    border: none;
    font-size: 11px;
    padding: 9px 15px;
    margin: 0;
    background: transparent;
    color: #34383d;
    width: 100%;
    text-align: left;
    text-decoration: none;
    position: relative;

    &:hover {
      color: $red-mandy;
      text-decoration: none;
    }

    &:disabled {
      color: $grey-nobel;
    }
  }
}

.hoveredItem {
  position: relative;

  &:hover {
    .dropdown {
      display: block;
    }
  }
}

.clickedItem {
  position: relative;
}

.clickedDropdown,
.dropdown {
  position: absolute;
  top: 0;
  left: 100%;
  padding-left: 6px;
  z-index: 100;
}

.dropdown {
  display: none;
}

.clickedBtnRow {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}
