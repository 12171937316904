@import '../../../../colors';

.box {
  position: relative;
}

.btnBox {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid $grey-gainsboro-2;
  background: #fff;
  border-radius: 4px;
  display: flex;

  & > li {
    position: relative;

    &:hover {
      .tooltipBox {
        display: block;
      }
    }

    & + li {
      border-left: 1px solid $grey-gainsboro-2;
    }

    & > button {
      width: 41px;
      height: 25px;
      background: transparent;
      border: none;
      padding: 0;
      color: $midnight;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: $grey-storm-2;
      }
    }
  }
}

.popoverBox {
  z-index: 50;
  overflow: visible !important;
}

.popoverOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.tooltipBox {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 5;
  margin-top: 7px;
  transform: translateX(-50%);
  background: $white;
  font-size: 11px;
  text-transform: capitalize;
  white-space: nowrap;
  padding: 8px 10px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    left: 50%;
    margin-left: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 5px 7px;
    border-color: transparent transparent $white transparent;
  }
}
