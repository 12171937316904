@import '../../../colors';

.react-modal-content {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: $white-smoke-2;
  border-radius: 8px;
  margin-top: 60px;
  margin-bottom: 60px;
  transition: width 0.15s;
  max-width: 100vw;

  &.centered {
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-height: 100vh;
    overflow-y: auto;
    margin: 0;
  }

  &.xs {
    width: 300px;
  }
  &.xs1 {
    width: 320px;
  }
  &.xs4 {
    width: 340px;
  }
  &.xs2 {
    width: 400px;
  }
  &.xs3 {
    width: 470px;
  }
  &.sm {
    width: 500px;
  }
  &.sm2 {
    width: 550px;
  }
  &.sm3 {
    width: 600px;
  }
  &.md {
    width: 650px;
  }
  &.md2 {
    width: 700px;
  }
  &.md3 {
    width: 750px;
  }
  &.md4 {
    width: 800px;
  }
  &.md5 {
    width: 850px;
  }
  &.md6 {
    width: 850px;
  }
  &.md7 {
    width: 900px;
  }
  &.lg {
    width: 1100px;
  }
}

.react-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
}

.react-modal-close-btn {
  position: absolute;
  top: 34px;
  right: 35px;
  background: transparent;
  display: flex;
  border: none;
  padding: 0;
  z-index: 10;
  color: $blue-zodiac;
  transition: all 0.15s;

  &:hover {
    color: $grey-storm;
  }
}
