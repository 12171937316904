@import '../../../colors';

.box {
  padding-top: 40px;
}

.btn {
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.4;
  letter-spacing: 0.1em;
  color: $blue-bunting;
  text-transform: uppercase;
  background: $btn-secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 50px;

  &:hover {
    background: $btn-secondary--hover;
  }

  &:disabled {
    background: $btn-secondary--disabled;
  }
}
