@import '../../../../colors';

.showMoreBtnBox {
  position: relative;
}

.showMoreBtn {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background: transparent;
  border: none;
  padding: 0;
  font-size: 12px;
  line-height: 1;
  color: $blue-zodiac;
  display: flex;
  align-items: center;

  &.allComments {
    .arrowIcon {
      padding-bottom: 0;

      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.arrowIcon {
  margin-left: 20px;
  width: 30px;
  height: 20px;
  border: 1px solid $grey-gainsboro-2;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;

  &:hover {
    background: $white-smoke-2;
  }
}

.wrapper {
  overflow-y: auto;

  &.notEmpty {
    padding: 5px 0;
    max-height: calc(100vh - 350px);
    min-height: 65px;
    border-bottom: 1px solid $grey-gainsboro-2;
  }

  &.isCallView {
    flex-grow: 1;
    max-height: none;
    min-height: 1px;
    border-bottom: 1px solid $grey-gainsboro-2;
  }
}

.loaderBox {
  padding: 5px 20px;
  line-height: 1;
}
