@import '../../colors';

.listBox {
  width: 270px;
}

.checkBox {
  margin-right: 10px;
  padding: 0 1px;

  &.active {
    svg {
      opacity: 1;
    }
  }

  &.disabled {
    color: $grey-nobel;
  }

  svg {
    margin-top: -1px;
    opacity: 0;
  }
}
