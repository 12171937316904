@import '../../../colors';

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white-smoke-2;
  border-radius: 8px;
  max-width: 100vw;
  min-width: 240px;

  &.fullSize {
    width: 100vw;
    height: 100vh;

    .slideImage {
      :global(.image-box) {
        height: calc(100vh - 80px);
      }
    }
  }
}

.prevBtn,
.nextBtn {
  position: absolute;
  top: 50%;
  margin-top: -32px;
  width: 65px;
  height: 65px;
  border: none;
  padding: 0;
  background: rgba(216, 216, 216, 0.2);
  border-radius: 50%;
  z-index: 2;

  &:before {
    content: '';
    display: block;
    background: url('../../../assets/img/arrow-left.svg') no-repeat;
    background-size: contain;
    width: 17px;
    height: 25px;
    margin: auto;
    opacity: 1;
  }

  &:hover {
    background: rgba(216, 216, 216, 0.3);
  }

  &:disabled {
    opacity: 0;
  }
}

.prevBtn {
  left: -90px;
}

.nextBtn {
  right: -90px;

  &:before {
    transform: rotate(180deg);
  }
}

.headerBtnBox {
  display: flex;
  align-items: center;

  button {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0 10px;
    color: $blue-zodiac;
    line-height: 1;

    &:hover {
      color: $grey-storm;
    }
  }
}

.slideHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  padding: 15px 30px;
}

.downloadBtn {
  line-height: 1;
  color: $blue-zodiac;

  &:hover {
    color: $grey-storm;
  }
}

.closeBtnArea {
  width: 15px;
}

.slideImage {
  :global(.image-box) {
    min-height: 50px;
    width: 100%;
    cursor: grab;
  }

  :global([class*='TransformComponent']) {
    width: 100%;
  }

  img {
    margin: 0 auto;
    display: block;
    max-width: calc(100vw - 220px);
    max-height: calc(100vh - 100px);
    transition: all 0.15s;
  }
}

.slideVideo {
  position: relative;
}

.videoWrap {
  video {
    max-width: calc(100vw - 220px);
    max-height: calc(100vh - 100px);
    margin: 0 auto;
  }
}
