@import '../../../colors';

.cardItem {
  padding: 11px 11px 1px;
  background: $white-smoke-2;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: all 0.5s;
  position: relative;

  &.isUnread {
    border-color: #09f;
    box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.3);
  }

  &:hover {
    .headerActionsBox {
      opacity: 1;
    }
  }
}

.itemRow {
  display: flex;
  align-items: flex-start;
}

.avatarCol {
  flex-shrink: 0;
  width: 55px;
  padding-bottom: 10px;
}

.mainDataCol {
  flex-grow: 1;
  min-width: 1px;
}

.nameAndDataRow {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
}

.descriptionBox {
  position: relative;

  & > div {
    max-height: 45px;
    overflow: hidden;
  }
}

.footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 5px;
}

.headerActionsBox {
  position: absolute;
  right: 15px;
  top: -7px;
  z-index: 1;
  opacity: 0;
}
