@import '../../../colors';

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.box {
  width: 300px;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  color: $text-color;
  background: #fff;

  &.ownBox {
    width: 100%;
    max-height: inherit;
    background: transparent;
    box-shadow: none;
    border-radius: 0;

    .avatar {
      width: 100%;
      height: 400px;
    }
  }
}

.avatar {
  display: block;
  width: 300px;
  height: 300px;
}

.infoBox {
  padding: 20px;
}

.topRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.nameAndNickBox {
  padding-right: 15px;
}

.name {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 7px;
  word-break: break-word;
  color: $blue-zodiac;
}

.nickname {
  font-size: 10px;
  color: $blue-royal;
  padding-bottom: 20px;
  word-break: break-word;
}

.label {
  font-size: 10px;
  font-weight: 600;
  padding-bottom: 10px;
  color: $grey-nobel;
}

.field {
  font-size: 14px;
  padding: 0 0 10px;
  margin: 0 0 12px;
  border-bottom: 1px solid $grey-gainsboro-2;
  word-break: break-word;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}
