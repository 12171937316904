@import '../../../colors';

.checkboxItem {
  width: 68px;
  height: 40px;
  border-radius: 20px;
  background: #ffffff;
  border: 1px solid $grey-gainsboro-3;
  padding: 3px;
  position: relative;
}

.checkIcon {
  opacity: 0;
  position: absolute;
  top: 11px;
  left: 10px;
  color: #fff;
  transition: all 0.15s;
}

.checkboxCircle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $grey-light;
  position: absolute;
  top: 7px;
  left: 8px;
  transition: all 0.15s;
}

.isChecked {
  width: 100%;
  height: 100%;
  background: $yellow-koromiko;
  border-radius: 20px;

  .checkboxCircle {
    left: 35px;
    background: #fff;
  }

  .checkIcon {
    opacity: 1;
  }
}

.checkboxInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}
