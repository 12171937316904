@import '../../../../../colors';

.box {
  padding: 1px 20px 1px 10px;
  margin: 3px 0;
  border-left: 2px solid $grey-swan;
  position: relative;
}

.commentBody {
  font-size: 13px;
  word-break: break-word;

  p {
    white-space: pre-wrap;
  }
}

.name {
  font-size: 13px;
  font-weight: 600;
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  background: transparent;
  border: none;
  padding: 0;
  color: $grey;

  &:hover {
    color: $blue-zodiac;
  }
}
