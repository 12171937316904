.sendingInfo {
  font-size: 14px;
  line-height: 1.3;
}

.imageBox {
  text-align: center;
  padding-top: 15px;

  img {
    max-width: 100%;
  }
}

.backBtnBox {
  display: flex;
  justify-content: center;
}

.backBtn {
  width: auto;
  padding-left: 25px;
  padding-right: 25px;
}
