@import '../../../../../../colors';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5px;
}

.item {
  padding: 0 5px 5px 0;
  width: 300px;
  height: 170px;
}

.errorItem {
  padding: 0 20px 5px 0;
}
