@import '../../colors';

.showEditForm {
  position: absolute;
  top: 32px;
  right: 60px;
  border: none;
  padding: 0;
  color: $blue-zodiac;
  background: transparent;
  display: flex;

  &:hover {
    color: $grey-storm;
  }
}

.showProfileBtn {
  flex-shrink: 0;
  border: none;
  background: transparent;
  padding: 0;
}

.container {
  padding: 30px 0 0;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: $blue-zodiac;
  margin: 0 30px 30px;
}
