@import '../../../../colors';

.box {
  display: inline-block;
  position: relative;
  text-align: center;
  color: $yellow-koromiko;
}

.loader {
  animation: loaderRotate 1.5s linear infinite;
}

.percent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  color: $blue-zodiac;
}

@keyframes loaderRotate {
  100% {
    transform: rotate(360deg);
  }
}
