@import '../../colors';

.wrapper {
  padding: 20px 12px;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $white-smoke-2;
  border-radius: 8px;
  position: relative;
}

.headerBox {
  padding: 30px 30px 0;
}

.closeBtn {
  position: absolute;
  top: 34px;
  right: 35px;
  background: transparent;
  display: flex;
  border: none;
  padding: 0;
  z-index: 10;
  color: $blue-zodiac;
  transition: all 0.15s;

  &:hover {
    color: $grey-storm;
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: $blue-zodiac;
  margin-bottom: 30px;
}

.searchBox {
  padding-bottom: 15px;
  position: relative;
}

.content {
  overflow-y: auto;
  flex-grow: 1;
  height: 100%;
  padding-bottom: 30px;
}

.item {
  padding: 0 50px;
  margin-top: -1px;
  transition: background 0.15s;

  &:hover {
    background: $white-smoke-4;

    .itemContainer {
      border-bottom-color: transparent;
    }

    .itemShowInPostBox,
    .itemDownloadBox {
      opacity: 1;
    }
  }
}

.itemContainer {
  display: flex;
  align-items: center;
  padding: 6px 0 5px;
  border-bottom: 1px solid $grey-gainsboro-2;
}

.itemIconBox {
  width: 64px;
  height: 36px;
  flex-shrink: 0;
  background: $grey-whisper-4;
  margin-right: 20px;
  overflow: hidden;
}

.galleryItem {
  height: 100%;
  cursor: pointer;

  :global(.image-box) {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.fileIconBox {
  width: 27px;
  height: 21px;
  margin: 7px auto 0;
  position: relative;
}

.fileIcon {
  color: $blue-zodiac;
}

.fileExtension {
  font-family: pfdintextcomppro, sans-serif;
  font-size: 12px;
  color: $blue-zodiac;
  text-transform: uppercase;
  line-height: 1;
  position: absolute;
  right: 0;
  bottom: -1px;
  background: $grey-whisper-4;
}

.itemName {
  width: 230px;
  font-size: 12px;
  font-weight: 500;
  color: $midnight;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.itemCreatedAt {
  flex-grow: 1;
  font-size: 12px;
  color: $grey-storm-2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.itemShowInPostBox {
  flex-shrink: 0;
  padding-right: 20px;
  opacity: 0;
}

.itemShowInPostBtn {
  min-height: 32px;
  padding-right: 18px;
  padding-left: 18px;
}

.itemDownloadBox {
  flex-shrink: 0;
  opacity: 0;
}

.itemDownloadBtn {
  display: block;
  line-height: 1;
  color: $grey-storm-2;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $midnight;
  }
}

.noFoundBox {
  text-align: center;
  padding: 20px;
  font-size: 24px;
  font-weight: 600;
  color: $blue-zodiac;
}

.loaderWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  color: $blue-zodiac;
  padding: 15px;

  &.large {
    height: 100%;
  }
}

.loaderBox {
  padding-bottom: 25px;
}
