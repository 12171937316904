.box {
  position: absolute;
  right: 15px;
  top: 20px;
  z-index: 2;
}

.questionBox,
.thanksBox {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.questionBox {
  z-index: 2;

  &.hide {
    opacity: 0;
    animation: hideQuestion 0.5s ease;
    pointer-events: none;
  }
}

.thanksBox {
  z-index: 1;
  opacity: 0;

  &.show {
    animation: showThanks 1.3s ease;
  }
}

.btn {
  display: flex;
  border: none;
  background: transparent;
  padding: 0;
  transition: all, 0.15s;

  &:hover {
    transform: scale(1.3);
  }

  img {
    flex-shrink: 0;
    width: 16px;
  }

  &.btnYes {
    padding-bottom: 4px;
  }

  &.btnNo {
    padding-top: 4px;
  }
}

.questionText {
  font-size: 12px;
  font-weight: 600;
  color: #333333;
  padding: 0 7px;
}

.thanksText {
  font-size: 12px;
  font-weight: 600;
  color: #333333;
}

@keyframes hideQuestion {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes showThanks {
  0% {
    opacity: 0;
  }
  //25% {
  //  opacity: 0;
  //}
  40% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
