@import '../../colors';

.topicTitleBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: $header-color;
  border-radius: 8px 8px 0 0;
  color: #fff;
  text-align: center;
  padding: 15px 10px;
  height: 80px;
  font-size: 18px;
  font-weight: 600;
}

.box {
  flex-shrink: 0;
  background: $header-color;
  border-radius: 8px 8px 0 0;
  color: #fff;
  padding: 8px 15px;
  height: 80px;
  position: relative;
}

.menuBtn {
  position: absolute;
  top: 7px;
  right: 10px;
  background: transparent;
  border: none;
  color: $white;
  padding: 0 5px;

  &:hover {
    color: $grey-silver;
  }
}

.popoverBox {
  z-index: 500;
  overflow: visible !important;
}

.title {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 2px;
}

.subTitle {
  font-size: 9px;
  padding-bottom: 9px;
}

.percentDescription {
  padding-bottom: 3px;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0.1em;
}

.percentBox {
  height: 7px;
  background: #ff8656;
  position: relative;
}

.percentLine {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: $amulet-green;
}
