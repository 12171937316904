@import '../../../colors';

.popoverBox {
  z-index: 1100;
  overflow: visible !important;
}

.tooltip {
  font-size: 10px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
  word-break: break-word;

  &.light {
    color: $midnight;
    background: #fff;
  }

  &.dark {
    color: #fff;
    background: $midnight;
  }
}
