@import '../../../../colors';

.wrapper {
  padding-right: 15px;
}

.buttonsBox {
  display: flex;
  border-radius: 15px;

  &.noStatus {
    background: transparent;
    border: 1px solid $midnight;

    .arrowBtn {
      color: $midnight;
    }

    .currentStatusBtn {
      height: 22px;
    }
  }

  // to do
  &.ff000000-0000-0000-0000-000000000009 {
    background: $status-to-do;
  }
  // assigned
  &.ff000000-0000-0000-0000-000000000001 {
    background: $status-assigned;
  }
  // in progress
  &.ff000000-0000-0000-0000-000000000003 {
    background: $status-in-progress;
  }
  // completed
  &.ff000000-0000-0000-0000-000000000008 {
    background: $status-completed;
  }
  // approved
  &.ff000000-0000-0000-0000-000000000002 {
    background: $status-approved;
  }
}

.currentStatusBtn {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  height: 24px;
  min-width: 10px;

  img {
    height: 100%;
  }

  &:hover {
    opacity: 0.7;
  }
}

.noStatusTitle {
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 900;
  letter-spacing: 0.0962em;
  color: $midnight;
  padding: 0 11px 0 12px;
  display: block;
}

.arrowBtn {
  border: none;
  background: transparent;
  padding: 0 10px 0 0;
  margin: 0;
  color: $white;

  &:hover {
    opacity: 0.7;
  }

  svg {
    display: block;
  }
}

.popoverBox {
  z-index: 500;
  overflow: visible !important;
}

.popoverOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.listBox {
  padding-top: 15px;
  background: $white;
  border-radius: 8px;
  box-shadow: 5px 5px 24px rgba(0, 0, 0, 0.15);
}

.loaderBox {
  padding: 15px;
  text-align: center;
}

.listItem {
  padding: 0 15px 15px;

  &.hidden {
    display: none;
  }
}

.listBtn {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  display: block;

  img {
    display: block;
    height: 24px;
  }

  &:hover {
    opacity: 0.85;
  }
}

.deleteStatusBtn {
  display: block;
  width: 100%;
  border: none;
  border-radius: 0 0 8px 8px;
  background: $grey-whisper-2;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 900;
  color: $blue-zodiac;
  letter-spacing: 0.1em;
  padding: 14px 5px;

  &:hover {
    color: $grey-storm;
  }
}
