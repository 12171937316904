@import '../../../colors';

.label {
  font-size: 10px;
  font-weight: 600;
  color: $midnight;
  padding-bottom: 4px;
}

.field {
  display: block;
  width: 100%;
  border: 1px solid $grey-gainsboro-2;
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 14px;
  height: 50px;
  outline: none;
  color: $text-color;
  background: #fff;

  &:read-only,
  &:disabled {
    color: $grey-dim;
    opacity: 0.8;
  }

  &.error {
    border-color: $red-sunset;
  }
}
