@import '../../colors';

.box {
  width: 300px;
  height: calc(100vh - 20px);
  display: flex;
  flex-direction: column;
  flex: none;
  margin: 20px 0 0 12px;
  box-shadow: 10px 10px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px 8px 0 0;

  &:not(.collapsedSidebar) {
    [class*='sidebarCollapseBtn_btn'] {
      opacity: 0;
    }

    &:hover {
      [class*='sidebarCollapseBtn_btn'] {
        opacity: 1;
      }
    }
  }

  &.collapsedSidebar {
    width: 84px;
  }
}

.mainContainer {
  background: $sidebar-color;
  height: calc(100% - 160px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 15px;

  &::-webkit-scrollbar {
    width: 0;
  }
}
