@import '../../../colors';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.15);
  animation: showCallNotification 1s ease;
}

.box {
  width: 420px;
  background: $white;
  border-radius: 8px;
  padding: 25px 30px 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.closeBtn {
  position: absolute;
  top: 25px;
  right: 30px;
  color: $blue-zodiac;

  &:hover {
    color: $grey-storm;
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
}

.text {
  padding-top: 25px;
}

@keyframes showCallNotification {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
