@import '../../../colors';

.wrapper {
  position: relative;
  overflow: hidden;

  :global(.react-tagsinput-tag) {
    background: $grey-whisper-4;
    display: inline-block;
    vertical-align: top;
    padding: 10px 37px 10px 10px;
    border-radius: 8px;
    margin: 0 5px 5px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    height: 40px;
    font-size: 14px;
    line-height: 1.4;
    position: relative;
  }

  :global(.react-tagsinput-remove) {
    cursor: pointer;
    background: url('../../../assets/img/x-icon.svg') no-repeat 0 0;
    background-size: contain;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -9px;

    &:hover {
      opacity: 0.85;
    }
  }

  :global(.react-tagsinput-input) {
    max-width: 100%;

    input {
      background: transparent;
      border: 0;
      color: #000;
      font-size: 14px;
      line-height: 1.4;
      outline: none;
      padding: 8px 5px;
      margin-bottom: 5px;
      height: 24px;
      max-width: calc(100% - 10px);
    }
  }
}
