@import '../../../../colors';

.wrapper {
  background: $white;
  border-radius: 0 0 8px 8px;
  margin: -10px 0 6px;
  padding-top: 10px;
  box-shadow: 10px 10px 24px rgba(0, 0, 0, 0.1);
}

.box {
  height: 72px;
  padding: 10px 60px 10px 35px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.closeBtn {
  position: absolute;
  top: 50%;
  right: 35px;
  margin-top: -10px;
  padding: 0;
  border: none;
  background: transparent;
  color: $blue-zodiac;

  &:hover {
    color: $grey-storm;
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  padding-right: 30px;
}

.buttonsBox {
  display: flex;
  align-items: center;
}

.btn {
  background: rgba($midnight, 0.2);
  border: none;
  border-radius: 15px;
  padding: 6px 10px;
  font-size: 8px;
  font-weight: 600;
  color: $midnight;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  &:hover {
    background: rgba($midnight, 0.3);
  }

  & + .btn {
    margin-left: 10px;
  }
}
