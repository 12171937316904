@import '../../../../../colors';

.wrapper {
  background: $white-smoke-3;
  padding: 15px 25px 10px 60px;
  margin-left: -60px;
}

.row {
  display: flex;
}

.nameCol,
.urlCol {
  input {
    height: 30px;
  }
}

.nameCol {
  flex-grow: 1;
}

.urlCol {
  flex-grow: 3;
  padding-left: 10px;
}

.btnCol {
  padding-left: 10px;
  flex-shrink: 0;
}

.btn {
  min-height: 30px;
  min-width: 85px;
}
