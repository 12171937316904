@import '../../../../colors';

.titleBox {
  display: flex;
  align-items: center;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: $blue-zodiac;
  min-width: 1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btnPencil {
  border: none;
  background: transparent;
  padding: 0;
  margin-left: 10px;
  color: $blue-zodiac;

  &:hover {
    color: $grey-storm;
  }
}

.editBox {
  position: relative;
  width: 330px;
  max-width: 100%;

  input {
    height: 40px;
    padding-right: 70px;
  }
}

.closeBtn,
.saveBtn {
  position: absolute;
  border: none;
  background: transparent;
  padding: 0;
  color: $blue-zodiac;

  &:hover {
    color: $grey-storm;
  }

  &:disabled {
    pointer-events: none;
  }
}

.closeBtn {
  top: 9px;
  right: 40px;
}

.saveBtn {
  top: 9px;
  right: 10px;
}

.errorBox {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 11px;
}
