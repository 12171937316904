@import '../../../../colors';

.infoLabel {
  padding: 6px 10px;
}

.infoRow {
  display: flex;
  align-items: center;
}

.infoIconBox {
  flex-shrink: 0;
  margin-right: 10px;
}

.infoIcon {
  color: $yellow-koromiko;
  display: block;
  line-height: 1;
  padding: 5px;
  border-radius: 50%;
  background: rgba($yellow-koromiko, 0.2);
  animation: recordIcon 1.5s linear infinite;
}

.infoText {
  flex-grow: 1;
  font-size: 12px;
  font-weight: 500;
  color: $midnight;
}

.infoBtnBox {
  flex-shrink: 0;
  padding-left: 10px;

  button {
    min-height: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@keyframes recordIcon {
  0% {
    background: rgba($yellow-koromiko, 0);
  }
  50% {
    background: rgba($yellow-koromiko, 0.3);
  }
  100% {
    background: rgba($yellow-koromiko, 0);
  }
}
