@import '../../../colors';

.cardItem {
  padding: 11px;
  background: $white-smoke-2;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: all 0.5s;
  position: relative;

  &.isUnread {
    border-color: #09f;
    box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.3);
  }

  &:hover {
    .headerActionsBox {
      opacity: 1;
    }
  }
}

.itemRow {
  display: flex;
  align-items: flex-start;
}

.avatarCol {
  display: flex;
  flex-shrink: 0;
  width: 55px;
  padding-bottom: 10px;

  img {
    &:hover {
      opacity: 0.85;
    }
  }
}

.mainDataCol {
  flex-grow: 1;
  min-width: 1px;
}

.nameAndDataRow {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
}

.sharedMainData {
  background: $grey-whisper-2;
  margin-bottom: 10px;
  border-radius: 8px;
}

.headerActionsBox {
  position: absolute;
  right: 15px;
  top: -7px;
  opacity: 0;
}

.imgAndDescBox {
  display: flex;
  align-items: flex-start;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 15px 0 10px;
  border-top: 1px solid $grey-gainsboro-2;
}

.footerLeftCol {
  display: flex;
}
