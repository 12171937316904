@import '../../colors';

.loginLinkBox {
  font-size: 16px;
  text-align: center;
  margin-top: 60px;

  a {
    color: $orange;
    font-weight: 600;

    &:hover {
      color: $grey-raven;
      text-decoration: none;
    }
  }
}
