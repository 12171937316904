@import '../../../colors';

.box {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    padding: 0 5px 5px 0;
    position: relative;
  }
}

.statusBtn {
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $grey-gainsboro-2;
  border-radius: 15px;
  background: #fff;
  height: 24px;
  font-size: 11px;
  font-weight: 600;
  color: $midnight;
  line-height: 1;
  padding: 1px 3px;

  &:hover {
    background: $blue-solitude;
  }

  img {
    height: 18px;
    flex-shrink: 0;
    margin-right: 2px;
  }

  &.dotsBtn {
    min-width: 44px;
    cursor: default;

    &:hover {
      background: #fff;
    }
  }
}

.counter {
  padding: 0 6px;
}

.popoverBox {
  z-index: 500;
  overflow: visible !important;
}

.reactedUsersBox {
  width: 150px;
  background: #000;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 4px 5px;
  font-size: 13px;
  line-height: 1.4;
  word-break: break-word;
  transition: opacity 0.2s;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -3px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
  }
}
