.wrapper {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 50;
}

.notification {
  display: flex;
  justify-content: center;
  position: relative;
}

.btnGoUp {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: rgba(69, 76, 94, 0.91);
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.3;
  border: none;
  padding: 9px 15px;
}

.arrowBox {
  margin-right: 8px;
  margin-top: -2px;
}
