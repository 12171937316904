.wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.btn {
  width: auto;
  min-height: 40px;
}

.loaderBox {
  padding-left: 5px;
}
