@import '../../../colors';

.modalHeader {
  padding: 30px;
}

.modalContent {
  padding: 0 30px 30px;
  background: $white;
  border-radius: 8px;
}

.description {
  color: $text-color;
}

.tabBox {
  display: flex;
}

.tabBtn {
  width: 50%;
  border: 1px solid $grey-whisper-3;
  border-bottom: none;
  background: $grey-whisper-3;
  color: $grey;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 16px 5px;

  &:nth-of-type(1) {
    border-right: none;
    border-top-left-radius: 8px;
  }

  &:nth-last-of-type(1) {
    border-left: none;
    border-top-right-radius: 8px;
  }

  &:hover {
    background: $white-smoke-3;
  }

  &.active {
    color: $blue-bunting;
    background: $white;
    pointer-events: none;
  }
}

.tabContent {
  padding: 30px;
  background: $white;
  border-radius: 0 0 8px 8px;
}

.row {
  display: flex;
}

.goalCol {
  flex-grow: 1;
}

.percentCol {
  width: 150px;
  flex-shrink: 0;
  padding-left: 20px;
}

.percentField {
  position: relative;
  padding-right: 25px;

  input {
    text-align: center;
  }
}

.percentSign {
  position: absolute;
  top: 10px;
  right: 0;
  font-size: 20px;
  font-weight: 500;
  color: $grey-nobel;
}

.nameRightBox {
  flex-grow: 1;
}

.avatarBox {
  flex-shrink: 0;
  padding-right: 30px;
  margin-bottom: 10px;
}

.avatarInfo {
  width: 70px;
  height: 70px;
}

.dropZoneWrapper {
  outline: none;
  width: 70px;
  height: 70px;
}

.dropZoneHover {
  opacity: 0;
  background: rgba(0, 0, 0, 0.08);
  text-align: center;
  color: lighten($grey-nobel, 5%);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.15s;
  border-radius: 50%;

  &.show {
    opacity: 1;
  }
}

.dropZoneBox {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  &:not(.isDisabled):hover {
    .dropZoneHover {
      opacity: 1;
      cursor: pointer;
    }
  }
}

.avatar {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.cropperWrap {
  padding: 0 30px 30px;
}

.cropperBox {
  width: 100%;
  height: 300px;
  margin-bottom: 20px;
}

.cropperButtonsBox {
  display: flex;
  justify-content: space-between;

  & > button {
    width: 48%;
  }
}

.closeCropperBtn {
  background: $grey-very-light;

  &:hover {
    background: $grey-silver;
  }
}

.label {
  font-size: 10px;
  font-weight: 600;
  color: $midnight;
  padding-bottom: 4px;
}

.fieldBox {
  padding-bottom: 20px;
}

.dateCol {
  width: 50%;
  padding-right: 10px;
}

.typeCol {
  width: 50%;
  padding-left: 10px;
}

.topicTypeRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  margin-top: 10px;
  border-top: 1px solid $grey-gainsboro-2;
}

.topicTypeTitle {
  font-weight: 600;
  color: $midnight;
}

.topicTypeCol {
  width: 235px;
  flex-shrink: 0;
}

.submitBox {
  padding-top: 20px;
}
