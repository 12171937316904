@import '../../../../colors';

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: $blue-zodiac;
  padding-bottom: 10px;
  white-space: pre-wrap;
  word-break: break-word;
}
