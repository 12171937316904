@import '../../../../colors';

.wrapper {
  position: relative;
  background: $white-smoke-2;
  padding: 9px 15px;
  border-radius: 8px 8px 0 0;
}

.title {
  font-size: 12px;
  font-weight: 500;
  color: $midnight;
  padding-bottom: 2px;
}

.btn {
  display: block;
  font-size: 10px;
  border: none;
  background: transparent;
  padding: 0;
  color: $midnight;

  &:hover {
    color: $red-mandy;
  }
}

.dropdown {
  position: absolute;
  top: 0;
  right: 100%;
  padding-right: 6px;
}

.dropdownBox {
  background: $white;
  border-radius: 8px;
  box-shadow: 0 2px 11px rgba(0, 0, 0, 0.2);
  width: 170px;
  max-height: 250px;
  overflow-y: auto;
}

.dropdownTitle {
  font-size: 12px;
  font-weight: 600;
  color: $midnight;
  padding: 14px 15px;
}

.topic {
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 10px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    border-radius: 0 5px 5px 0;
    background: $blue-denim;
  }

  &.isObjective {
    &:before {
      background: $amulet-green;
    }
  }
}

.topicAvatarBox {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 10px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.topicName {
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 1px;
}

.emptyBox {
  font-size: 10px;
  text-align: center;
  padding: 15px;
}
