@import '../../../colors';

.field {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 1.6;
  color: $text-color;
  padding: 12px 15px;
  background: #fff;
  border: 1px solid $grey-gainsboro-2;
  border-radius: 8px;
  min-height: 50px;
  outline: none;
  resize: none;

  &:read-only,
  &:disabled {
    color: $grey;
  }

  &.error {
    border-color: $red-sunset;
  }
}
