@import '../../../../../../colors';

.wrapper {
  min-width: 80px;
  max-width: 260px;
  max-height: 100vh;
  overflow-y: auto;
}

.loaderBox {
  padding: 5px 15px;
  text-align: center;
}

.errorBox {
  text-align: center;
  padding: 15px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &.active {
    color: $sea-green;

    .btnIcon {
      opacity: 1;
    }
  }

  &.loading {
    color: inherit;

    .btnIcon {
      color: inherit;
    }
  }
}

.btnIcon {
  color: $sea-green;
  margin: -2px 0 0 10px;
  opacity: 0;
}
