@import '../../../../colors';

.popoverBox {
  z-index: 500;
  overflow: visible !important;
}

.popoverOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.listWrapper {
  width: 300px;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: #fff;
  padding-top: 1px;
}

.listOverflow {
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  height: 270px;
  padding: 0 15px;
}

.listItem {
  width: 50%;
  padding: 5px;
}

.searchBox {
  margin: 14px 15px 10px;
  position: relative;

  input {
    display: block;
    width: 100%;
    outline: none;
    font-size: 12px;
    line-height: 1.2;
    font-style: italic;
    padding: 9px 30px 9px 15px;
    border-radius: 4px;
    border: none;
    color: $midnight;
    background: $white-smoke-3;
    height: 32px;
  }
}

.searchBtn {
  position: absolute;
  top: 7px;
  right: 4px;
  z-index: 2;
  padding: 2px 5px 1px;
  border: none;
  background: none;
  color: rgba(0, 0, 0, 0.5);

  svg {
    vertical-align: top;
  }
}

.noFoundBox {
  font-size: 14px;
  line-height: 1.15;
  text-align: center;
  padding-top: 80px;
  color: #858585;
  width: 100%;
}

.noFoundIcon {
  padding: 6px;

  button {
    pointer-events: none;
  }
}
