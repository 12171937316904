@import '../../../colors';

.wrapper {
  padding: 0 30px 30px;
}

.labelBox {
  font-size: 10px;
  font-weight: 600;
  padding-bottom: 5px;
}

.fieldBox {
  padding-bottom: 15px;
}

.input {
  border: 1px solid $grey-gainsboro-2;
  border-radius: 8px;
  background: #fff;
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
  outline: none;

  &.error {
    border-color: $red-sunset;
  }

  &:disabled {
    color: $grey-dim;
    background: rgba(255, 255, 255, 0.6);
  }
}

.dropZoneWrapper {
  outline: none;
  margin-bottom: 30px;
}

.dropZoneHover {
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.15s;
}

.dropZoneBox {
  width: 400px;
  height: 400px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
}

.avatar {
  display: block;
  height: 100%;
  margin: 0 auto;
}

.cropperBox {
  width: 400px;
  height: 250px;
  margin: 0 auto;
}

.avatarDescription {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 30px;
  font-size: 10px;
  font-weight: 600;
  color: $grey-dim;
  background: $white-smoke-3;
  margin-bottom: 30px;

  & > div {
    width: 45%;
  }
}

.zoomIcon {
  margin-bottom: 20px;
  color: #525252;
}

.uploadNewBtn {
  margin-top: 12px;
}

.buttonsBox {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding-top: 15px;

  button {
    width: 48%;
  }

  .cancelBtn {
    background: $grey-very-light;

    &:hover {
      background: $grey-silver;
    }

    &:disabled {
      background: $grey-very-light;
    }
  }
}
