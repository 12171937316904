@import '../../../../colors';

.wrap {
  position: relative;
}

.showTitleBtn {
  position: absolute;
  top: -18px;
  left: -60px;
  background: $white-smoke-3;
  border: 1px solid transparent;
  border-right-color: $grey-gainsboro-2;
  border-radius: 8px 0 0 8px;
  padding: 0;
  color: $blue-zodiac;
  height: 60px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;

  &:hover {
    opacity: 0.85;
  }

  &.active {
    padding: 0;
    border-color: transparent;
    background: transparent;
  }
}

.postTitle {
  border: none;
  padding: 0 10px 0 0;
  margin-bottom: 17px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  color: $blue-zodiac;
  background: transparent;
  min-height: 10px;
}
