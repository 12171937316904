@import '../../../../colors';

.box {
  button {
    color: $grey;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    padding: 0;

    &:hover {
      color: $blue-zodiac;
    }
  }
}
