@import '../../../colors';

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  max-width: 650px;
  margin-bottom: 10px;
  padding-left: 15px;
  border-left: 3px solid $grey-gainsboro;
  word-break: break-word;
}

.title {
  font-size: 16px;
  font-weight: 500;
}

.img {
  width: 80px;
  margin-left: 15px;
}
