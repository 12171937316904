@import '../../colors';

.hiddenModal {
  opacity: 0;
}

.container {
  padding: 30px 30px 20px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: $blue-zodiac;
  margin: 0 0 25px;
}

.infoBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid $grey-gainsboro-2;
}

.mainDataBox {
  display: flex;
  align-items: center;
}

.workspaceAvatarBox {
  margin-right: 15px;
}

.workspaceName {
  font-size: 18px;
  font-weight: 600;
  color: $blue-zodiac;
  min-width: 1px;
  margin-right: 15px;
  word-break: break-word;
}
