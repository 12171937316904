@import '../../colors';

.wrapper {
  position: relative;
}

.btn {
  border: none;
  background: transparent;
  padding: 0;
  color: $midnight;
  transition: all 0.15s;

  &:hover {
    color: $grey-storm;
  }
}

.listBox {
  width: 180px;
  position: absolute;
  right: -3px;
  top: 100%;
  margin-top: 6px;
  z-index: 20;

  &.collapsedSidebar {
    right: auto;
    left: 0;
  }

  &.hidden {
    display: none;
  }
}
