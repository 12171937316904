@import '../../../../../../colors';

.formContainer {
  width: 380px;
  padding: 25px 30px 30px;
  border-radius: 8px;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.19);
  background: $white-smoke-2;
  position: relative;
}

.xBtn {
  position: absolute;
  top: 27px;
  right: 35px;
  background: transparent;
  border: none;
  padding: 0;
  color: $blue-zodiac;
  transition: all 0.15s;

  &:hover {
    color: $grey-storm;
  }
}

.formTitle {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 15px;
  color: $blue-zodiac;
}

.tokenBox {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $grey-gainsboro-2;
}

.tokenDescription {
  font-size: 12px;
  color: $grey-dim;
  padding-right: 25px;
}

.tokenBtn {
  width: 130px;
}

.fieldBox {
  padding-bottom: 20px;
}
