@import '../../../../colors';

.box {
  display: flex;
  flex-wrap: wrap;
}

.item {
  width: 250px;
  margin: 0 0 15px 0;
  padding-right: 15px;
}

.itemHeader {
  display: flex;
  align-items: center;
  color: $blue-zodiac;
}

.icon {
  margin-right: 8px;
  flex-shrink: 0;
}

.fileName {
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 1px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
}

.removeFileBtn {
  flex-shrink: 0;
  background: transparent;
  border: none;
  margin-left: 8px;
  padding: 0;
  color: $grey-dim;
  display: flex;

  &:hover {
    color: $midnight;
  }
}

.audioBox,
.videoBox {
  margin-top: 5px;

  audio {
    display: block;
    outline: none;
    border: 1px solid $grey-gainsboro-2;
    border-radius: 30px;
  }

  video {
    display: block;
    outline: none;
  }
}

.audioBox {
  height: 32px;
}

.videoBox {
  height: 140px;
}

.imageBox {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 1px;
}

.filesBox {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: auto;
    max-width: 100%;
  }
}

.imageItem {
  margin: 0 20px 20px 0;
  flex: none;
  position: relative;
}

.imagePreview {
  overflow: hidden;
  border-radius: 8px;
  width: 40px;
  height: 40px;

  & > div {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.removeImageBtn {
  position: absolute;
  top: -14px;
  right: -14px;
  width: 28px;
  height: 28px;
  padding: 4px;
  border: none;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey-manatee;

  svg {
    width: 100%;
  }

  &:hover {
    color: $grey-storm;
  }
}
