@import '../../colors';

.forgotPasswordBox {
  text-align: right;
  line-height: 1;
  padding-bottom: 10px;
}

.forgotPassword {
  font-size: 11px;
  font-weight: 600;
  font-style: italic;
  color: $blue-denim;
  text-decoration: none;

  &:hover {
    color: $grey-raven;
    text-decoration: none;
  }
}

.authorizationBox {
  padding-top: 20px;
}

.authorizationBtn {
  background: $white;
  color: $midnight;
  border: 1px solid $grey-gainsboro-2;

  svg {
    margin-right: 15px;
  }

  &:hover {
    background: $white-smoke;
  }
}

@media all and (max-width: 767px) {
  .returnBtn {
    top: -20px;
  }
}
