@import '../../../../colors';

.btn {
  display: block;
  border: none;
  background: $grey-whisper-4;
  border-radius: 8px;
  padding: 5px 8px 5px 10px;
  font-size: 12px;
  font-weight: 600;
  color: $midnight;
  width: 103px;
  height: 40px;
  position: relative;
  text-align: left;

  &:hover {
    background: $grey-whisper-3;
  }

  &.btnIndividual {
    text-align: right;

    .slider {
      left: 5px;
    }
  }
}

.slider {
  position: absolute;
  top: 5px;
  left: 68px;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 4px;
  transition: all 0.15s;

  & > span {
    position: absolute;
    top: 8px;
    height: 14px;
    width: 1px;
    background: $grey-swan;

    &:nth-of-type(1) {
      left: 40%;
    }

    &:nth-of-type(2) {
      left: 50%;
    }

    &:nth-of-type(3) {
      left: 60%;
    }
  }
}
