@import '../../../colors';

.box {
  margin-left: 10px;
}

.tooltip {
  max-width: 400px;
}

.icon {
  color: #fff;
}
