@import '../../../colors';

$border-color: $grey-gainsboro-2;

.wrapper {
  border-radius: 8px;
}

.isCallView {
  .tabContent {
    border-bottom-color: transparent;
    border-radius: 0 0 0 8px;
  }

  ul.tabNav > li > a {
    padding: 21px 10px;
  }

  &:not(.hasOneThread) {
    .avatarCol {
      margin: 0;
    }

    .nameCol {
      display: none;
    }
  }
}

.tabHeader {
  display: flex;
  position: relative;
  margin-bottom: -1px;
}

.tabNavWrap {
  min-width: 1px;
}

ul.tabNav {
  display: flex;
  flex-wrap: nowrap;
  min-width: 1px;

  &.wide {
    & > li {
      flex-basis: 200px;
    }
  }

  & > li {
    padding-right: 0;
    position: relative;
    max-width: 500px;
    background: $grey-whisper-3;
    overflow: hidden;
    margin-right: 2px;
    border: 1px solid $grey-gainsboro-2;
    border-bottom-width: 0;

    & > a {
      display: block;
      padding: 8px 10px;
      color: $text-color;
      position: relative;

      &:focus {
        outline: none;
      }
      &:hover {
        text-decoration: none;
      }
    }

    &:nth-of-type(1) {
      border-top-left-radius: 8px;
    }

    &:nth-last-of-type(1) {
      border-top-right-radius: 8px;
    }

    &:hover {
      background: $white-smoke-2;
    }

    &:global(.active) {
      background: #fff;
      z-index: 2;

      & > a {
        color: $text-color;
        cursor: default;
      }
    }
  }
}

.navItem {
  display: flex;
  align-items: center;
  position: relative;
}

.avatarCol {
  flex-shrink: 0;
  margin-right: 10px;
}

.nameCol {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  color: $text-color;
}

.avatarsGroupBox {
  display: flex;

  .avatarItem {
    &:nth-child(n + 2) {
      margin-left: -7px;
    }
  }
}

.avatarItem {
  display: block;
  border-radius: 50%;
  padding: 2px;
  background: #fff;

  img {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}

.memberLength {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  background: $grey-whisper-3;
  border: 2px solid #fff;
  font-size: 10px;
  font-weight: 600;
  margin-left: -7px;
}

.dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: $red-sunset;
  border: 2px solid #fff;
  position: absolute;
  top: -4px;
  left: -4px;
}

.shareBtnBox {
  position: relative;
}

.shareBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 100%;
  border: none;
  background: transparent;
  border-top-right-radius: 8px;
  padding: 0;
  outline: none;
  color: $midnight;
  transition: all 0.15s;

  &:hover {
    color: $grey-dim;
    background: $white-smoke-2;
  }
}

.tabContent {
  position: relative;
  background: #fff;
  border: 1px solid $border-color;
  border-radius: 0 0 8px 8px;
}

.popoverBox {
  z-index: 50;
  overflow: visible !important;
}

.popoverOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.memberPopover {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 35px;
    display: none;
  }

  &.bottom {
    &:before {
      display: block;
      top: -35px;
    }
  }

  &.top {
    &:before {
      display: block;
      bottom: -35px;
    }
  }
}

.memberList {
  padding: 15px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid $grey-whisper-3;
  max-width: 270px;
  max-height: 200px;
  overflow-y: auto;
}

.memberListItem {
  display: flex;
  align-items: center;

  & + .memberListItem {
    padding-top: 12px;
  }

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 10px;
  }

  span {
    min-width: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    font-weight: 600;
  }
}
