@import '../../../../colors';

.postDescription {
  padding-top: 3px;

  :global(.public-DraftEditorPlaceholder-root) {
    color: $grey-nobel;
    font-size: 14px;
    line-height: 1.45;
    top: 0;
    left: 0;
  }

  :global(.public-DraftEditor-content) {
    color: #000;
    font-size: 14px;
    line-height: 1.45;
    padding: 0 10px 0 0;
    max-height: 150px;
    overflow-y: auto;
  }

  &.previewInDefaultMode {
    overflow: hidden;
    height: 23px;
  }
}

.mainLabel {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  color: $blue-zodiac;
  background: #fff;
  pointer-events: none;
  display: flex;
  align-items: center;
  z-index: 5;
}
