@import '../../../colors';

.wrapper {
  padding-top: 40px;
  flex-grow: 1;
}
.container {
  width: 100%;
  height: 100%;
  max-width: 440px;
  margin: 0 auto;
}

.title {
  font-size: 12px;
  font-weight: 600;
  color: $midnight;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding-bottom: 25px;
}

.tabsBox {
  display: flex;
  padding-bottom: 8px;

  button {
    border: none;
    border-bottom: 1px solid lighten($grey-manatee, 7%);
    background: transparent;
    padding: 0 5px 10px;
    font-size: 12px;
    font-weight: 600;
    color: lighten($grey-manatee, 7%);
    text-transform: uppercase;
    letter-spacing: 0.2em;
    width: 50%;

    &:hover {
      color: $grey-storm-2;
      border-color: $grey-storm-2;
    }

    &:disabled {
      color: $midnight;
      border-color: $midnight;
    }
  }
}

.usersList {
  max-height: 400px;
  overflow-y: auto;
  padding: 15px 0;
}

.userWrapper {
  display: flex;
  align-items: center;
  padding: 5px 0;
  min-height: 40px;

  &:hover {
    .userButtonsBox {
      opacity: 1;
    }
  }
}

.avatarBox {
  flex-shrink: 0;
  padding-right: 10px;

  img {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}

.userName {
  flex-grow: 1;
  color: $midnight;
  padding-right: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 1px;
}

.userButtonsBox {
  flex-shrink: 0;
  opacity: 0;
  padding: 0 5px;
}

.userBtn {
  min-height: 32px;
  padding-left: 15px;
  padding-right: 15px;
}
