@import '../../colors';

.noAccessWrapper,
.wrapper {
  height: 100%;
  overflow-y: scroll;
  padding: 6px 0 5px 12px;
  margin-right: 7px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
}

.noAccessWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.noAccessTitle {
  max-width: 170px;
  font-size: 16px;
  font-weight: 600;
  color: $blue-bunting;
}
