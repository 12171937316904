@import '../../../colors';

.popoverBox {
  z-index: 1000;
  overflow: visible !important;
}

.popoverOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.wrap {
  flex-shrink: 0;
}

.portal {
  position: fixed;
  z-index: 1001;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  padding: 0;
  color: $grey;
  position: relative;

  &:not(:disabled):hover {
    color: $blue-zodiac;
  }
}

.betaLabel {
  position: absolute;
  top: -2px;
  left: 8px;
  pointer-events: none;
}

.iconBox {
  flex-shrink: 0;
  width: 30px;
  padding-right: 8px;
  text-align: center;
}

.wrapperLabel {
  position: fixed;
  z-index: 2000;
  top: 10px;
  left: 10px;
  background: $white;
  box-shadow: 10px 10px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
