@import '../../../colors';

.dropdownContent {
  width: 140px;
  padding: 7px 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 11px rgba(0, 0, 0, 0.2);
  max-height: 160px;
  overflow-y: auto;
}

.userBox {
  padding: 4px 15px;
  display: flex;
  align-items: center;
}

.userAvatar {
  flex-shrink: 0;
  margin-right: 10px;
  width: 15px;

  img {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
}

.userName {
  font-size: 10px;
  color: $text-color;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.error {
  font-size: 10px;
  padding: 10px;
}

.emptyList {
  text-align: center;
  font-size: 12px;
  padding: 1px 0 2px;
}
