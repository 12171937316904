@import '../../../colors';

.box {
  background: $blue-bunting;
  border-radius: 8px;
  padding: 20px;
  margin: 0 30px;
  height: 310px;
  color: $grey-spindle;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  position: relative;
}

.notFoundImg {
  position: absolute;
  bottom: 0;
  right: -15px;
}
