@import '../../../colors';

.btn {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  padding: 0;
  border: none;
  color: #e55050;
  background: $white-smoke-3;
  border-radius: 50%;
  transition: all 0.15s;

  &:hover {
    background: $white-smoke-2;
  }
}
