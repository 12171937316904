@import '../../../../colors';

.wrap {
  width: 100%;
  height: 100%;

  audio {
    display: block;
    outline: none;
    border: 1px solid $grey-gainsboro-2;
    border-radius: 30px;
  }
}
