@import '../../../colors';

.wrapper {
  padding-top: 10px;
}

.buttonsBox {
  text-align: center;
}

.timeBtn {
  width: 100px;
  height: 34px;
  background: transparent;
  border: 1px solid $grey-light;
  padding: 0;
  color: $midnight;
  font-weight: 900;
  font-size: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  &:nth-of-type(1) {
    border-radius: 8px 0 0 8px;
  }

  &:nth-last-of-type(1) {
    border-radius: 0 8px 8px 0;
  }

  &:hover {
    background: $grey-whisper;
  }

  &.active {
    background: $grey-light;
  }

  & + .timeBtn {
    border-left-width: 0;
  }
}

.popoverOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.popoverBox {
  z-index: 1001;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background: $white;
  overflow: visible !important;
}

.popoverContainer {
  padding: 15px;
  width: 300px;
}

.popoverTitle {
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 20px;
}

.datepickerRow {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.datepickerCol {
  width: 47%;
}

.datepickerLabel {
  font-size: 10px;
  font-weight: 600;
  color: $midnight;
  padding-bottom: 4px;
}

.datepickerInput {
  height: 40px;
}

.applyBtn {
  min-height: 40px;
}

.timeDescription {
  padding-top: 15px;
  text-align: center;
  font-size: 12px;
  color: $grey-nobel;
}

.timePeriod {
  font-weight: 600;
  color: $midnight;
}
