@import '../../colors';

.header {
  height: 190px;
  background: $midnight;
  border-radius: 7px 7px 0 0;
  padding: 60px 30px 0;
  margin-bottom: 70px;

  img {
    display: block;
    margin: 0 auto;
  }
}

.content {
  padding: 30px;
}

.title {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 900;
  color: $blue-zodiac;
  text-align: center;
}

.description {
  line-height: 1.5;
  color: $grey-dim;
  text-align: center;
  padding: 30px 0;
}

.btn {
  border: none;
  border-radius: 8px;
  padding: 17px 10px;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.4;
  letter-spacing: 0.1em;
  color: $blue-bunting;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  background: $btn-secondary;
  display: block;
  width: 100%;

  &:hover {
    color: $blue-bunting;
    text-decoration: none;
    background: $btn-secondary--hover;
  }
}
